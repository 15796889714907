import 'react-toastify/dist/ReactToastify.css';

import React, { type PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { ToastContainer } from 'react-toastify';

const containerPortalRoot = document.querySelector('#toast-root');

const ToasterProvider: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<>
			{children}
			<>
				{createPortal(
					<ToastContainer position="top-center" autoClose={2000} icon={null} style={{ zIndex: 1000000000 }} />,
					containerPortalRoot,
				)}
			</>
		</>
	);
};

export default ToasterProvider;
