import { APP_MODE } from 'const';
import { MODE_SWITCH_MESSAGES } from 'lib/service-worker/messages.const';
import localforage from 'localforage';
import type { AppMode } from 'models/IApp';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { authActions } from 'store/reducers/auth';

import { useAppDispatch } from './redux';
import { useAppMode } from './useAppMode';

export type ModeSwitchStatus = 'idle' | 'processing' | 'success' | 'error';

export const useSwitchModeSW = () => {
	const { mode, isOffline, isOnline, updatedAt, switchModeTo } = useAppMode();
	const [switchStatus, setSwitchStatus] = useState<ModeSwitchStatus>('idle');
	const dispatch = useAppDispatch();

	useEffect(() => {
		const eventController = new AbortController();
		const { signal } = eventController;

		const handleMessage = async (event: MessageEvent) => {
			switch (event.data.type) {
				case MODE_SWITCH_MESSAGES.fromSW.isProcessing:
					setSwitchStatus('processing');
					break;
				case MODE_SWITCH_MESSAGES.fromSW.offlineModeOn:
					switchModeTo(APP_MODE.offline);
					setSwitchStatus('success');
					break;
				case MODE_SWITCH_MESSAGES.fromSW.onlineModeOn:
					switchModeTo(APP_MODE.online);
					setSwitchStatus('success');
					break;
				case MODE_SWITCH_MESSAGES.fromSW.dataIsSynced:
					setSwitchStatus('success');
					break;
				case MODE_SWITCH_MESSAGES.fromSW.dataSyncIsFailed:
					setSwitchStatus('error');
					break;
				case MODE_SWITCH_MESSAGES.fromSW.modeSwitchFailed: {
					if (event.data.reason && event.data.reason?.code == 401) {
						toast.error('Час вашої сесії сплив! Будь ласка, авторизуйтеся.');
						localforage.removeItem('auth-tokens');
						dispatch(authActions.clearLoggedInUser());
						return;
					}
					setSwitchStatus('error');
					break;
				}
				default:
					break;
			}
		};

		if ('navigator' in window && 'serviceWorker' in window.navigator) {
			window.navigator.serviceWorker.addEventListener('message', handleMessage, { signal });
		}

		return () => {
			eventController.abort();
		};
	}, []);

	const reset = () => {
		setSwitchStatus('idle');
	};

	const switchTo = useCallback(
		(requestMode: AppMode | null, withDataFetch: boolean = true) => {
			if ('navigator' in window && 'serviceWorker' in window.navigator) {
				if (switchStatus === 'error') {
					setSwitchStatus('idle');
				}

				if (!requestMode) {
					window.navigator.serviceWorker.controller?.postMessage({ type: MODE_SWITCH_MESSAGES.toSW.triggerSyncDataInBackground });
					return;
				}

				if (mode === APP_MODE.online && requestMode === APP_MODE.offline) {
					if (!withDataFetch) {
						switchModeTo(APP_MODE.offline);
						setSwitchStatus('success');
					} else {
						window.navigator.serviceWorker.controller?.postMessage({ type: MODE_SWITCH_MESSAGES.toSW.triggerOfflineModeSwitch });
					}
				} else if (mode === APP_MODE.offline && requestMode === APP_MODE.online) {
					window.navigator.serviceWorker.controller?.postMessage({ type: MODE_SWITCH_MESSAGES.toSW.triggerOnlineModeSwitch });
				}
			}
		},
		[mode, switchStatus],
	);

	const isProcessing = switchStatus === 'processing';
	const isSuccess = switchStatus === 'success';
	const isError = switchStatus === 'error';
	const isIdle = switchStatus === 'idle';

	return {
		isOfflineMode: isOffline,
		isOnlineMode: isOnline,
		switchedAt: updatedAt,
		isProcessing,
		isSuccess,
		switchTo,
		isError,
		isIdle,
		reset,
		mode,
	};
};
