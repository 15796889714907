export const SKIP_WAITING_MESSAGE = 'SKIP_WAITING';
export const WAITING_IS_SKIPPED_MESSAGE = 'WAITING_IS_SKIPPED';
export const MODE_SWITCH_MESSAGES = {
	fromSW: {
		isProcessing: 'MODE_SWITCH_IS_PROCESSING',
		offlineModeOn: 'OFFLINE_MODE_IS_ON',
		onlineModeOn: 'ONLINE_MODE_IS_ON',
		modeSwitchFailed: 'MODE_SWITCH_IS_FAILED',
		dataIsSynced: 'SYNC_OFFLINE_DATA_IN_BACKGROUND_SUCCESS',
		dataSyncIsFailed: 'SYNC_OFFLINE_DATA_IN_BACKGROUND_FAILURE',
	},
	toSW: {
		triggerOfflineModeSwitch: 'SWITCH_TO_OFFLINE_MODE',
		triggerOnlineModeSwitch: 'SWITCH_TO_ONLINE_MODE',
		triggerSyncDataInBackground: 'SYNC_OFFLINE_DATA_IN_BACKGROUND',
	},
};
