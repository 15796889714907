import { type DragEndEvent, closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { breakPoints } from 'const';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import React, { lazy, memo, Suspense, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { uuid } from 'utils/shared';
import { isObject } from 'utils/type-guards';

import styles from './lib/table/styles/style.module.css';
import type { IProps } from './lib/table/types/general';
import type { ColumnOrderModel, PinningModel, RowSelectionModel, VisibilityModel } from './lib/table/types/table';
import Pagination from './Pagination';
import ColumnControlsMenu from './TableComponents/ColumnControlsMenu';
import DragAlongCell from './TableComponents/DragAlongCell';
import DraggableTableHeader from './TableComponents/DraggableHeader';
import PinColumnMenu from './TableComponents/PinColumnMenu';
import SortColumnMenu from './TableComponents/SortColumnMenu';
import { autoscrollOptions, modifiers } from './TableComponents/StickyHeader/config';

const MobileTableView = lazy(() => import('./TableComponents/MobileTableView'));
const StickyHeader = lazy(() => import('./TableComponents/StickyHeader'));

const fallbackModelState = {};
const fallbackOrderModelState = [];

const defaultGetRowId = <TData,>(row: TData): string => {
	if (isObject<TData>(row) && 'id' in row) {
		return row.id as string;
	}
};
const columnSelectId = uuid();
const columnSelectFragmentId = uuid();

const Table = <TData,>({ actionsPanelSlot: ActionsPanelSlot, ...props }: IProps<TData>) => {
	const {
		allData,
		columns,
		filledFromSecondCell,
		selectable,
		onClickRow,
		className,
		extendRowProps,
		defineRowDisabledForSelection,
		visibilityModel = fallbackModelState,
		pinningModel = fallbackModelState,
		onPinningModelChange,
		rowSelectionModel = fallbackModelState,
		onRowSelectionModelChange,
		getRowId = defaultGetRowId,
		getCanSelectRow,
		shouldHighlightDisableRow = true,
		columnOrderModel = fallbackOrderModelState,
		onColumnOrderModelChange,
		stickyHeader = false,
		stickyHeaderOptions,
	} = props;

	const [data, setData] = useState(allData);
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });
	const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

	const onColumnOrderChange = useCallback(
		(order: AnyArg) => {
			const pinnedLeft = (isObject(pinningModel) && 'left' in pinningModel && (pinningModel.left as string[])) || [];
			const pinnedRight = (isObject(pinningModel) && 'right' in pinningModel && (pinningModel.right as string[])) || [];
			const currentOrder = typeof order === 'function' ? order(columnOrderModel) : order;

			const newOrder = [...pinnedLeft, ...currentOrder.filter((id) => !pinnedLeft.includes(id) && !pinnedRight.includes(id)), ...pinnedRight];

			onColumnOrderModelChange?.(newOrder);
		},
		[pinningModel, onColumnOrderModelChange],
	);

	const table = useReactTable<TData>({
		columns,
		data,
		debugTable: true,
		state: {
			columnVisibility: visibilityModel as VisibilityModel,
			columnPinning: pinningModel as PinningModel,
			rowSelection: rowSelectionModel as RowSelectionModel,
			columnOrder: columnOrderModel as ColumnOrderModel,
		},
		getRowId,
		enableRowSelection: getCanSelectRow,
		manualPagination: true,
		manualSorting: true,
		getCoreRowModel: getCoreRowModel<TData>(),
		onRowSelectionChange: onRowSelectionModelChange,
		onColumnPinningChange: onPinningModelChange,
		onColumnOrderChange: onColumnOrderChange,
		columnResizeMode: 'onChange',
	});

	const { beforeTable, afterTable } = props.childrenLikeSlots ?? {};
	const canRenderHeaderCellControlMenu = [props?.pinningModel].filter(Boolean).length > 0;

	const handleClickOnRow = useCallback((row: TData) => {
		onClickRow?.(row);
	}, []);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;
			if (active && over && active.id !== over.id) {
				if (!onColumnOrderModelChange) return;

				const pinnedLeft = (isObject(pinningModel) && 'left' in pinningModel && (pinningModel.left as string[])) || [];
				const pinnedRight = (isObject(pinningModel) && 'right' in pinningModel && (pinningModel.right as string[])) || [];
				const isPinned = (id: string) => !!pinnedLeft?.includes(id) || !!pinnedRight?.includes(id);

				onColumnOrderModelChange((columnOrder) => {
					const oldIndex = columnOrder.indexOf(active.id as string);
					const newIndex = columnOrder.indexOf(over.id as string);

					if (isPinned(active.id as string) || isPinned(over.id as string)) {
						return columnOrder;
					}

					return arrayMove(columnOrder, oldIndex, newIndex);
				});
			}
		},
		[pinningModel],
	);

	useEffect(() => {
		setData(allData);
	}, [allData]);

	const shouldRenderActionSlot = !!ActionsPanelSlot && Object.keys(rowSelectionModel ?? {}).length > 0;
	const isDraggable = !!props.columnOrderModel;

	if (isMobile) {
		return (
			<Suspense fallback={<PageContentSkeleton />}>
				<MobileTableView tableInstance={table} {...props} />
				<Pagination />
			</Suspense>
		);
	}

	const theadEl = (
		<thead className="relative" data-has-column-order-model={!!props?.columnOrderModel}>
			{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id}>
					{headerGroup.headers.map((header) => {
						const isSelectCheckbox = header.column.id === 'select';

						if (shouldRenderActionSlot && isSelectCheckbox) {
							return (
								<th data-action-slot="true" colSpan={columns.length} key={columnSelectId}>
									<ActionsPanelSlot
										tableInstance={table}
										allData={allData}
										rowSelectionModel={rowSelectionModel}
										onRowSelectionModelChange={onRowSelectionModelChange}
										width={table.getTotalSize()}
										checkbox={
											<React.Fragment key={columnSelectFragmentId}>
												{!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
											</React.Fragment>
										}
									/>
								</th>
							);
						}

						if (shouldRenderActionSlot && !isSelectCheckbox) return null;

						return (
							<DraggableTableHeader key={header.id} header={header} isDraggable={isDraggable}>
								{canRenderHeaderCellControlMenu && (
									<ColumnControlsMenu>
										<>
											{header.column.getCanPin() && <PinColumnMenu headerInstance={header} tableInstance={table} />}
											{header.column.getCanSort() && (
												<SortColumnMenu
													appendQueryKey={header.column.columnDef?.meta?.appendQueryKey}
													sortColumnKey={header.column.columnDef?.meta?.sortQueryKey ?? ''}
												/>
											)}
										</>
									</ColumnControlsMenu>
								)}
							</DraggableTableHeader>
						);
					})}
				</tr>
			))}
		</thead>
	);

	const tbodyEl = (
		<tbody>
			{table.getRowModel().rows.map((row) => {
				const resolvedRow = defineRowDisabledForSelection?.(row) || row;
				const { style: extendedStyles, className: extendedRowClassName, ...restExtendedProps } = extendRowProps?.(resolvedRow) ?? {};

				return (
					<tr
						data-tbody-table-row
						key={row.id}
						className={clsx(styles.tableRow, 'text-sx-regular', extendedRowClassName, {
							[styles.canNotSelect]: !row.getCanSelect() && shouldHighlightDisableRow,
						})}
						style={{ cursor: onClickRow && 'pointer', ...(isObject(extendedStyles) && extendedStyles) }}
						onClick={(e) => {
							e.stopPropagation();
							handleClickOnRow(row.original);
						}}
						{...restExtendedProps}
					>
						{row.getVisibleCells().map((cell) => {
							return (
								<SortableContext key={cell.id} items={columnOrderModel} strategy={horizontalListSortingStrategy}>
									<DragAlongCell
										key={cell.id}
										cell={cell}
										row={row}
										selectable={selectable}
										filledFromSecondCell={filledFromSecondCell}
										isDraggable={isDraggable}
									/>
								</SortableContext>
							);
						})}
					</tr>
				);
			})}
		</tbody>
	);

	if (stickyHeader) {
		return (
			<>
				{beforeTable}
				<div data-action-slot-is-on={shouldRenderActionSlot} className={clsx(styles.stickyTableContainer, className)}>
					<Suspense>
						<StickyHeader
							minWidth={table.getTotalSize()}
							onDragEnd={handleDragEnd}
							columnOrderModel={columnOrderModel}
							visibilityModel={visibilityModel}
							options={stickyHeaderOptions}
						>
							{theadEl}
							{tbodyEl}
						</StickyHeader>
					</Suspense>
				</div>

				{afterTable}
			</>
		);
	}

	return (
		<>
			{beforeTable}
			<div className={clsx('table-container', styles.tableContainer, className)}>
				<DndContext
					collisionDetection={closestCenter}
					modifiers={modifiers}
					autoScroll={autoscrollOptions}
					onDragEnd={handleDragEnd}
					sensors={sensors}
				>
					<table
						className={styles.table}
						style={{
							width: '100%',
							minWidth: table.getTotalSize(),
						}}
					>
						{theadEl}
						{tbodyEl}
					</table>
				</DndContext>
			</div>

			{afterTable}
		</>
	);
};

export default memo(Table);
