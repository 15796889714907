import { baseApiUrl } from 'const';
import { prepareUrl } from 'utils/shared';

type CreateUrlFromBinaryOptions = {
	mime: string;
};

export const createUrlFromBinary = (binary: BlobPart, options?: CreateUrlFromBinaryOptions) => {
	const type = options?.mime || 'application/pdf';

	const blob = new Blob([binary], { type });
	return URL.createObjectURL(blob);
};
export const createUrlFromPart = (urlSegment: string) => {
	const [root] = baseApiUrl.split('/api') ?? [];
	const url = root + prepareUrl(urlSegment);

	return url;
};
