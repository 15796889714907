import { permissionTokens } from './permissionTokens';

const {
	changeClient,
	changeContract,
	changeOrganization,
	changeStock,
	changeIsPaidStatus,
	changeIsWithoutPaymentStatus,
	changeToCashRegisterPaymentStatus,
	addProducts,
	addServices,
	deleteProducts,
	viewCommentDrawer,
	changeProductsTable,
	changeServicesTable,
	interactWithTableColumns,
	interactWithChangePriceTool,
} = permissionTokens;

export function getBaseOrderPermissions() {
	return [
		changeClient,
		changeContract,
		changeOrganization,
		changeStock,
		changeIsPaidStatus,
		changeIsWithoutPaymentStatus,
		changeToCashRegisterPaymentStatus,
		deleteProducts,
		interactWithTableColumns,
		interactWithChangePriceTool,
		viewCommentDrawer,
	];
}
export function getBaseSuborderPermissions() {
	return [
		changeClient,
		changeContract,
		changeOrganization,
		changeStock,
		changeIsPaidStatus,
		changeIsWithoutPaymentStatus,
		changeToCashRegisterPaymentStatus,
		addProducts,
		addServices,
		deleteProducts,
		changeProductsTable,
		changeServicesTable,
		interactWithTableColumns,
		interactWithChangePriceTool,
	];
}
