import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from 'const';
import localforage from 'localforage';
import { StoreToken } from 'models/auth';
import { useCallback, useState } from 'react';
import apiClient from 'services/apiClient';
import { baseQuery } from 'store/config/base-query';
import { ExportOrdersFile, ExportOrdersPayload, UnprocessedExportOrdersFile } from 'store/reducers/export/types';
import { createUrlFromBinary, createUrlFromPart } from 'utils/documents';
import { createErrorFactory, transformError } from 'utils/errors/errors';

export const exportApi = createApi({
	reducerPath: 'exports',
	baseQuery: baseQuery(),
	endpoints: (builder) => ({
		exportOrders: builder.mutation<ExportOrdersFile, ExportOrdersPayload>({
			queryFn: async ({ params, ...payload }) => {
				const authTokens = await localforage.getItem<StoreToken>('auth-tokens');
				const { accessToken, tokenType } = authTokens || {};
				try {
					const response = await apiClient.post<UnprocessedExportOrdersFile>(API_ENDPOINT.exportOrders() + '?' + params, payload, {
						headers: {
							Authorization: `${tokenType} ${accessToken}`,
						},
					});

					const disposition = response.headers['Content-Disposition'] ?? '';
					const name = getFilenameFromDisposition(disposition);

					return {
						data: {
							downloadUrl: response.data.download_url,
							message: response.data.message,
							name,
						},
					};
				} catch (err) {
					const error = createErrorFactory(err);
					const exception = transformError(error);

					return {
						error: exception,
					};
				}
			},
		}),
		getExportOrdersColumnsList: builder.query<{ label: string; value: string }[], void>({
			query: () => ({ url: API_ENDPOINT.exportOrdersColumnsList(), method: 'GET' }),
		}),
	}),
});

export const { useExportOrdersMutation, useGetExportOrdersColumnsListQuery } = exportApi;

export const useDownloadFileOnDevice = () => {
	const [isLoading, setIsLoading] = useState(false);

	const download = useCallback((binary: Blob | BlobPart | ArrayBuffer, fileName: string) => {
		setIsLoading(true);
		const mime = fileName.split('.').at(-1);
		const url = createUrlFromBinary(binary, { mime });

		const a = document.createElement('a');
		a.href = url;
		a.download = fileName || url;
		document.body.appendChild(a);
		a.click();

		document.body.removeChild(a);
		URL.revokeObjectURL(url);
		setIsLoading(false);
	}, []);

	const downloadUrl = useCallback((urlPart: string, fileName: string) => {
		setIsLoading(true);
		const url = createUrlFromPart(urlPart);

		const a = document.createElement('a');
		a.href = url;
		a.download = fileName || url;
		document.body.appendChild(a);
		a.click();

		document.body.removeChild(a);
		setIsLoading(false);
	}, []);

	return {
		isDownloading: isLoading,
		download,
		downloadUrl,
	};
};

function getFilenameFromDisposition(disposition: string | null): string | undefined {
	if (!disposition) return;
	const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
	const matches = filenameRegex.exec(disposition);
	if (matches != null && matches[1]) {
		return matches[1].replace(/['"]/g, '');
	}
}
