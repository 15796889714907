import clsx from 'clsx';
import React from 'react';
import { ReactComponent as ErrorIcon } from 'static/images/switch-mode/error-circle.svg';
import { ReactComponent as InfoIcon } from 'static/images/switch-mode/help-circle.svg';
import { ReactComponent as SuccessIcon } from 'static/images/switch-mode/success-circle.svg';
import { formatAndExtractDateTime } from 'utils/dates';

import FactorySpinner from './FactorySpinner';
import styles from './styles.module.css';
import type { ModeChangeStatusProps } from './types';

export const BaseModeChangeStatus: React.FC<ModeChangeStatusProps> = ({ className, icon, message, children }) => {
	return (
		<div className={clsx({ [styles.modeChangeStatus]: !!icon }, className)}>
			{icon}

			<p className="modal-body-content">{message}</p>

			{children}
		</div>
	);
};

export const PendingModeChangeStatus = ({ mode, date }: { mode: string; date: string | Date }) => {
	const isOnlineMode = mode === 'online';
	const [parsedDate, parsedTime] = formatAndExtractDateTime(new Date(date));
	const updatedAtText = !!date ? (
		<>
			Оновлено -{' '}
			<time>
				<span className={clsx(styles.highlightTime, styles.mediumBold)}>{parsedDate}</span> о{' '}
				<span className={clsx(styles.highlightTime, styles.mediumBold)}>{parsedTime}</span>
			</time>
		</>
	) : null;

	const text = isOnlineMode ? (
		<>
			Робота з данними відбувається по мережі. Залишки та ціни - <b>АКТУАЛЬНІ.</b>
		</>
	) : (
		<>
			Дані беруться з локального сховища. Залишки та ціни - <b>НЕ АКТУАЛЬНІ.</b> {updatedAtText}
		</>
	);

	return (
		<BaseModeChangeStatus
			icon={<InfoIcon className={styles.pending} />}
			message={
				<>
					<span className={clsx(styles.textCenter, styles.semiBold, styles.mb1)}>Зміна режима роботи</span>
					<span className={clsx(styles.textCenter)}>
						Ви зараз в <b className={styles.highlight}>{mode}</b>-режимі. {text} Перейти в інший режим?
					</span>
				</>
			}
		/>
	);
};

export const ErrorModeChangeStatus = () => {
	return (
		<BaseModeChangeStatus
			icon={<ErrorIcon className={styles.error} />}
			message={
				<>
					<span className={clsx(styles.textCenter, styles.semiBold, styles.mb1)}>Виникла помилка.</span>
					<span className={clsx(styles.textCenter)}>Будь ласка, спробуйте змінити режим ще раз або зробіть це пізніше.</span>
				</>
			}
		/>
	);
};

export const SuccessModeChangeStatus = ({ mode }: { mode: string }) => {
	return (
		<BaseModeChangeStatus
			icon={<SuccessIcon className={styles.success} />}
			message={
				<>
					<span className={clsx(styles.textCenter, styles.semiBold, styles.mb1)}>Завершено.</span>
					<span className={clsx(styles.textCenter)}>
						Тепер ви можете користуватися додатком у <b className={styles.highlight}>{mode}</b>-режимі.
					</span>
				</>
			}
		/>
	);
};

export const ProcessingModeChangeStatus = () => {
	return (
		<BaseModeChangeStatus
			message={
				<>
					<br />
					<span className={styles.semiBold}>Перемикаємо режим...</span>
					<br />
					<br />
					<span>Будь ласка, зачекайте, поки дані готуються.</span>
				</>
			}
		>
			<FactorySpinner />
		</BaseModeChangeStatus>
	);
};
export const ProcessingPrepareData = () => {
	return (
		<BaseModeChangeStatus
			message={
				<>
					<br />
					<br />
					<span>Будь ласка, зачекайте, поки дані готуються.</span>
				</>
			}
		>
			<FactorySpinner />
		</BaseModeChangeStatus>
	);
};

export const ProcessingPrepareDataSuccess = () => {
	return (
		<BaseModeChangeStatus
			icon={<SuccessIcon className={styles.success} />}
			message={
				<>
					<span className={clsx(styles.textCenter, styles.semiBold, styles.mb1)}>Завершено.</span>
					<span className={clsx(styles.textCenter)}>Тепер дані доступні для офлайн режиму у будь-який час.</span>
				</>
			}
		/>
	);
};

export const ProcessingPrepareDataError = () => {
	return (
		<BaseModeChangeStatus
			icon={<ErrorIcon className={styles.error} />}
			message={
				<>
					<span className={clsx(styles.textCenter, styles.semiBold, styles.mb1)}>Виникла помилка.</span>
					<span className={clsx(styles.textCenter)}>Будь ласка, спробуйте ще раз або зробіть це пізніше.</span>
				</>
			}
		/>
	);
};
