import { ROUTES_URLS } from 'const';
import React, { lazy, Suspense, useState } from 'react';
import type { RouteObject } from 'react-router-dom';

const ClientCreatePage = lazy(() => import('pages/ClientCreatePage'));
const ClientPage = lazy(() => import('pages/ClientPage'));
const Clients = lazy(() => import('pages/Clients'));
const Orders = lazy(() => import('pages/Orders'));
const ClientOrders = lazy(() => import('pages/ClientOrdersPage'));
const EmployeePreview = lazy(() => import('pages/Employee/EmployeePreview'));
const EmployeeCreate = lazy(() => import('pages/Employee/EmployeeCreate'));
const EmployeeEdit = lazy(() => import('pages/Employee/EmployeeEdit'));
const Employees = lazy(() => import('pages/Employees'));
const Documents = lazy(() => import('pages/Documents'));
const PaintToning = lazy(() => import('pages/PaintToning'));
const CommercialProposal = lazy(() => import('pages/CommercialProposal'));
const Prices = lazy(() => import('pages/Prices'));

const PDFViewer = lazy(() => import('components/PDFViewer'));
const Document = lazy(() => import('components/PDFViewer/components/Document'));
const Page = lazy(() => import('components/PDFViewer/components/Page'));
const Pagination = lazy(() => import('components/PDFViewer/components/Pagination'));
const Toolbar = lazy(() => import('components/PDFViewer/components/Toolbar'));
const DownloadTool = lazy(() => import('components/PDFViewer/components/Tools/DownloadTool'));
const FullscreenTool = lazy(() => import('components/PDFViewer/components/Tools/FullscreenTool'));
const PrinterTool = lazy(() => import('components/PDFViewer/components/Tools/PrinterTool'));
const ThumbnailsTool = lazy(() => import('components/PDFViewer/components/Tools/ThumbnailsTool'));
const ShareTool = lazy(() => import('components/PDFViewer/components/Tools/ShareTool'));
const Block = lazy(() => import('components/PDFViewer/ui/Block'));

const HaveNoPermissions = lazy(() => import('pages/Order/components/HaveNoPermissions'));
const OrderModule = lazy(() => import('pages/Order/OrderModule'));
const OrderSelectClientModule = lazy(() => import('pages/Order/OrderSelectClientModule'));
const OrderSelectProductModule = lazy(() => import('pages/Order/OrderSelectProductModule'));
const OrderSplitModule = lazy(() => import('pages/Order/OrderSplitModule'));
const PageErrorBoundary = lazy(() => import('./components/PageErrorBoundary'));
const DangerousActionsProvider = lazy(() => import('components/EmployeeProfileComponents/DangerousActionsProvider'));
const CreatePrice = lazy(() => import('pages/CrearePrice'));
const SavePricePage = lazy(() => import('pages/CrearePrice/SavePricePage'));
const OrderClientCardModule = lazy(() => import('pages/Order/OrderClientCardModule'));
const ExistingOrderController = lazy(() => import('pages/Order/OrderController').then((m) => ({ default: m.ExistingOrderController })));
const NewOrderController = lazy(() => import('pages/Order/OrderController').then((m) => ({ default: m.NewOrderController })));
const ForbiddenErrorView = lazy(() => import('components/ErrorViews').then((m) => ({ default: m.ForbiddenErrorView })));

import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import AwaitedExistingOrderRoute from 'pages/Order/OrderController/AwaitedExistingOrderRoute';
import AwaitedNewOrderRoute from 'pages/Order/OrderController/AwaitedNewOrderRoute';
import { PaintToningOrder } from 'pages/PaintToningOrder';

import AwaitedRoute from './components/AwaitedRoute';
import CanAddProductsRouteGuard from './components/permissions/CanAddProductsRouteGuard';
import CanSplitOrderRouteGuard from './components/permissions/CanSplitOrderRouteGuard';
import ProtectedRoute from './components/ProtectedRoute';
import { clientOrdersHandler, fetchClient } from './handlers/clients';
import { fetchEmployee, fetchEmployees } from './handlers/employees';
import { existingOrderDataLoader, newOrderDataLoader, ordersLoader, selectProductsHandler } from './handlers/orders';
import { paintToningLoader } from './handlers/paint-toning';

export const SharedLayoutRoutes: RouteObject[] = [
	{
		children: [
			{
				path: ROUTES_URLS.ORDERS,
				loader: ordersLoader,
				element: <Orders />,
			},
		],
	},
	// EXISTING ORDER PREVIEW AND EDITING FLOW
	{
		element: <AwaitedExistingOrderRoute>{({ data }) => <ExistingOrderController data={data} />}</AwaitedExistingOrderRoute>,
		path: ROUTES_URLS.ORDER_PREVIEW_ROOT,
		loader: existingOrderDataLoader,
		shouldRevalidate: ({ currentParams, nextParams }) => {
			return currentParams?.id !== nextParams?.id;
		},
		children: [
			{
				path: ROUTES_URLS.ORDER_ROOT_SEGMENT + '/' + ROUTES_URLS.ORDER_PREVIEW_SEGMENT,
				element: <OrderModule />,
			},
			{
				path: ROUTES_URLS.ORDER_ROOT_SEGMENT + '/' + ROUTES_URLS.ORDER_PREVIEW_SEGMENT + '/' + ROUTES_URLS.ORDER_PREVIEW_SPLIT_ORDER_SEGMENT,
				element: (
					<CanSplitOrderRouteGuard>
						<OrderSplitModule />
					</CanSplitOrderRouteGuard>
				),
			},
			{
				path: ROUTES_URLS.ORDER_ROOT_SEGMENT + '/' + ROUTES_URLS.ORDER_PREVIEW_SEGMENT + '/' + ROUTES_URLS.ORDER_PREVIEW_ADD_PRODUCTS_SEGMENT,
				element: (
					<CanAddProductsRouteGuard fallback={<HaveNoPermissions />}>
						<OrderSelectProductModule />
					</CanAddProductsRouteGuard>
				),
			},
			{
				path:
					ROUTES_URLS.ORDER_ROOT_SEGMENT +
					'/' +
					ROUTES_URLS.ORDER_PREVIEW_SEGMENT +
					'/' +
					ROUTES_URLS.ORDER_PREVIEW_CLIENT_CARD_SEGMENT +
					'/:clientId',
				loader: fetchClient,
				element: <OrderClientCardModule />,
			},
		],
	},
	// NEW ORDER CREATION FLOW
	{
		element: <AwaitedNewOrderRoute>{({ data }) => <NewOrderController data={data} />}</AwaitedNewOrderRoute>,
		loader: newOrderDataLoader,
		path: ROUTES_URLS.ORDER_NEW_ROOT + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT,
		children: [
			{
				index: true,
				element: <OrderModule />,
			},

			{
				path: ROUTES_URLS.ORDER_NEW_SPLIT_ORDER_SEGMENT,
				element: (
					<CanSplitOrderRouteGuard>
						<OrderSplitModule />
					</CanSplitOrderRouteGuard>
				),
			},

			{
				path: ROUTES_URLS.ORDER_NEW_SET_CLIENT_SEGMENT,
				element: <OrderSelectClientModule />,
			},
			{
				path: ROUTES_URLS.ORDER_NEW_ADD_PRODUCTS_SEGMENT,
				element: (
					<CanAddProductsRouteGuard>
						<OrderSelectProductModule />
					</CanAddProductsRouteGuard>
				),
			},
			{
				path: ROUTES_URLS.ORDER_NEW_CLIENT_CARD_SEGMENT + '/:clientId',
				loader: fetchClient,
				element: <OrderClientCardModule />,
			},
		],
	},
	{
		path: ROUTES_URLS.CLIENTS,
		element: <Clients />,
	},
	{
		path: ROUTES_URLS.CLIENT_PAGE,
		errorElement: <PageErrorBoundary />,
		loader: fetchClient,
		element: (
			<AwaitedRoute fallback={<PageContentSkeleton />}>
				<ClientPage />
			</AwaitedRoute>
		),
	},
	{
		path: ROUTES_URLS.CLIENT_CREATE,
		element: (
			<ProtectedRoute I="create" a="clients.client" fallback={<ForbiddenErrorView />}>
				<ClientCreatePage />
			</ProtectedRoute>
		),
	},
	{
		path: ROUTES_URLS.CLIENT_ORDERS,
		loader: clientOrdersHandler,
		element: (
			<AwaitedRoute fallback={<PageContentSkeleton />}>
				<ClientOrders />
			</AwaitedRoute>
		),
	},
	{
		path: ROUTES_URLS.EMPLOYEES,
		loader: fetchEmployees,
		element: (
			<ProtectedRoute I="view" an="users" fallback={<ForbiddenErrorView />}>
				<Employees />
			</ProtectedRoute>
		),
	},
	{
		path: ROUTES_URLS.EMPLOYEE,
		loader: fetchEmployee,
		element: (
			<ProtectedRoute I="view" an="users.user" fallback={<ForbiddenErrorView />}>
				<DangerousActionsProvider>
					<EmployeePreview />
				</DangerousActionsProvider>
			</ProtectedRoute>
		),
	},
	{
		path: ROUTES_URLS.EMPLOYEE_CREATE,
		element: (
			<ProtectedRoute I="create" an="users.user" fallback={<ForbiddenErrorView />}>
				<EmployeeCreate />
			</ProtectedRoute>
		),
	},
	{
		path: ROUTES_URLS.EMPLOYEE_EDIT,
		loader: fetchEmployee,
		element: (
			<ProtectedRoute I="edit" an="users.user" fallback={<ForbiddenErrorView />}>
				<DangerousActionsProvider>
					<EmployeeEdit />
				</DangerousActionsProvider>
			</ProtectedRoute>
		),
	},
	{
		path: ROUTES_URLS.DOCUMENTS,
		element: <Documents />,
		loader: fetchClient,
	},
	{
		path: ROUTES_URLS.PAINT_TONING,
		element: <PaintToning />,
		loader: paintToningLoader,
	},
	{
		path: ROUTES_URLS.PRICES,
		element: <Prices />,
	},
	{
		path: ROUTES_URLS.PRICES_CREATE,
		element: <CreatePrice />,
		loader: selectProductsHandler,
	},
	{
		path: ROUTES_URLS.PRICES_SAVE,
		element: <SavePricePage />,
	},
	{
		path: ROUTES_URLS.COMMERCIAL_PROPOSAL,
		element: <CommercialProposal />,
	},
	{
		path: 'pdf',
		element: <PDFTestPage />,
	},
	{
		path: 'pto',
		element: <PaintToningOrder />,
	},
];

// example of a PDF viewer usage
function PDFTestPage() {
	const [isOpened, setIsOpened] = useState(false);

	const fileUrl = 'GitNotesForProfessionals.pdf';
	const handleClose = () => {
		setIsOpened(false);
	};

	const toggleOpen = () => {
		setIsOpened(!isOpened);
	};

	return (
		<div>
			<button
				type="button"
				onClick={toggleOpen}
				style={{
					padding: '12px 16px',
					backgroundColor: 'steelblue',
					color: 'white',
					borderRadius: '6px',
					display: 'block',
					margin: '12px auto 0 auto',
				}}
			>
				Reveal pdf
			</button>

			<Suspense fallback={<div>Loading...</div>}>
				{!!isOpened && (
					<PDFViewer embedMode="aboveAll" onClose={handleClose} filePath={fileUrl}>
						<Toolbar>
							<ThumbnailsTool />

							<Block ml="auto">
								<PrinterTool />
								<DownloadTool />
								<FullscreenTool />
								<ShareTool />
							</Block>
						</Toolbar>

						<Document>
							<Page />
						</Document>

						<Pagination />
					</PDFViewer>
				)}
			</Suspense>
		</div>
	);
}
