import { createColumnHelper } from '@tanstack/react-table';
import { HeaderCellContent, RegularCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import React from 'react';

const columnHelper = createColumnHelper<AnyArg>();

export const usePaintToningServicesColumns = () => {
	const columns = [
		columnHelper.accessor('service', {
			header: () => (
				<HeaderCell>
					<HeaderCellContent justify="flex-start" value="Послуги тонування" />
				</HeaderCell>
			),
			cell: () => (
				<RegularCell>
					<RegularCellContent color="var(--gray-900)" fontWeight="500" justify="flex-start">
						1
					</RegularCellContent>
				</RegularCell>
			),
		}),
		columnHelper.accessor('colorCode', {
			header: () => (
				<HeaderCell>
					<HeaderCellContent justify="flex-start" value="Код кольору" />
				</HeaderCell>
			),
			cell: () => (
				<RegularCell>
					<RegularCellContent justify="flex-start" width="100%">
						<input placeholder="Введіть код кольору..." />
					</RegularCellContent>
				</RegularCell>
			),
		}),
		columnHelper.accessor('collection', {
			header: () => (
				<HeaderCell>
					<HeaderCellContent justify="flex-start" value="Колекція" />
				</HeaderCell>
			),
			cell: () => (
				<RegularCell>
					<RegularCellContent justify="flex-start" width="100%">
						1
					</RegularCellContent>
				</RegularCell>
			),
		}),
		columnHelper.accessor('baseTone', {
			header: ({ column, table }) => (
				<HeaderCell>
					<HeaderCellContent
						sortable
						withIndeterminate
						disabled={table.getPrePaginationRowModel().rows.length < 2}
						column={column}
						justify="center"
						value="База"
					/>
				</HeaderCell>
			),
			cell: () => (
				<RegularCell>
					<RegularCellContent justify="center" width="100%">
						1
					</RegularCellContent>
				</RegularCell>
			),
		}),
		columnHelper.accessor('tare', {
			header: ({ column, table }) => (
				<HeaderCell>
					<HeaderCellContent
						sortable
						withIndeterminate
						disabled={table.getPrePaginationRowModel().rows.length < 2}
						column={column}
						justify="center"
						value="Тара"
					/>
				</HeaderCell>
			),
			cell: () => (
				<RegularCell>
					<RegularCellContent justify="center" width="100%">
						5кг
					</RegularCellContent>
				</RegularCell>
			),
		}),
		columnHelper.accessor('toning-price', {
			header: ({ column, table }) => (
				<HeaderCell>
					<HeaderCellContent
						sortable
						withIndeterminate
						disabled={table.getPrePaginationRowModel().rows.length < 2}
						column={column}
						justify="center"
						value="Ціна тонування"
					/>
				</HeaderCell>
			),
			cell: () => (
				<RegularCell>
					<RegularCellContent justify="center" width="100%">
						5кг
					</RegularCellContent>
				</RegularCell>
			),
		}),
		columnHelper.accessor('quantity', {
			header: () => (
				<HeaderCell>
					<HeaderCellContent justify="center" value="Кількість одиниць фарби" />
				</HeaderCell>
			),
			cell: () => (
				<RegularCell>
					<RegularCellContent justify="center" width="100%">
						1
					</RegularCellContent>
				</RegularCell>
			),
		}),
		columnHelper.accessor('action', {
			header: () => (
				<HeaderCell>
					<HeaderCellContent justify="center" value="Прибрати" />
				</HeaderCell>
			),
			cell: () => (
				<RegularCell justify="center">
					<RegularCellContent>1</RegularCellContent>
				</RegularCell>
			),
		}),
	];

	return columns;
};
