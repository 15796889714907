import clsx from 'clsx';
import React, { useMemo } from 'react';

import { useMainSearchStateAdapter } from './hooks/useMainSearchStateAdapter';
import type { MainSearchProps } from './lib/types';
import { MainSearchContext, MainSearchProvider } from './Provider';
import styles from './styles.module.css';

const MainSearch = ({ children, delay, className }: MainSearchProps) => {
	const { query, onChange } = useMainSearchStateAdapter({ delay });

	const context: MainSearchContext = useMemo(
		() => ({
			query,
			onChange,
		}),
		[onChange, query],
	);

	return (
		<MainSearchProvider value={context}>
			<div className={clsx(styles.mainSearch, className)}>
				<div className={clsx(styles.inputArea, styles.ring)}>{children}</div>
			</div>
		</MainSearchProvider>
	);
};

export default MainSearch;
