import OrderAlertDialoguesProvider from 'contexts/OrderAlertDialoguesProvider';
import { useAppSelector } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import type { Whoami } from 'models/auth';
import type { CatalogueService } from 'models/service';
import React from 'react';
import { useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';
import { selectOrderLikeEntity } from 'store/reducers/orders/selectors';

import { createOrderLikeEntity } from './lib/entity-creators';

type OrderLikeEntity = ReturnType<typeof createOrderLikeEntity>;

type AwaitedNewOrderRouteProps = {
	children: (props: { data: [OrderLikeEntity, Record<string, CatalogueService>, Whoami] }) => JSX.Element;
};

interface UseGetServicesQueryReturn {
	data: Record<string, CatalogueService>;
	isFetching: boolean;
}

const AwaitedNewOrderRoute: React.FC<AwaitedNewOrderRouteProps> = ({ children }) => {
	let order = useAppSelector(selectOrderLikeEntity);
	const { data: services, ...serviceReq } = useGetServicesQuery<UseGetServicesQueryReturn>();
	const { user } = useAuth();

	if (serviceReq.isFetching) return <PageContentSkeleton />;

	if (!order) {
		order = createOrderLikeEntity({ client: {}, manager: user, contract: { label: '', value: '' }, organization: { label: '', value: '' } });
	}

	const payload = {
		data: [order, services, user] as [OrderLikeEntity, Record<string, CatalogueService>, Whoami],
	};

	return <OrderAlertDialoguesProvider>{children(payload)}</OrderAlertDialoguesProvider>;
};

export default AwaitedNewOrderRoute;
