import { ROUTES_URLS } from 'const';
import { orderCanHooks, suborderCanHooks } from 'pages/Order/OrderAbility/can/hooks';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

const { useCanOrderAddProducts } = orderCanHooks;
const { useCanSuborderAddProducts } = suborderCanHooks;

type CanAddProductsRouteGuardProps = React.PropsWithChildren & {
	fallback?: React.ReactNode;
};

const CanAddProductsRouteGuard: React.FC<CanAddProductsRouteGuardProps> = ({ children, fallback }) => {
	const [searchParams] = useSearchParams();
	const suborderIndexRaw = searchParams.get('from');
	const suborderIndex = Number(suborderIndexRaw || 0);

	const canOrderAdd = useCanOrderAddProducts(suborderIndex);
	const canSuborderAdd = useCanSuborderAddProducts(suborderIndex);

	if (suborderIndex > 0 && canSuborderAdd) return children;
	if (canOrderAdd) return children;
	if (fallback) return fallback;

	return <Navigate replace to={ROUTES_URLS.HOME} />;
};

export default CanAddProductsRouteGuard;
