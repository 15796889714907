import { suborderPermissionTokens } from './permissionTokens';

const {
	addProducts,
	addServices,
	deleteProducts,
	deleteServices,
	interactWithChangePriceTool,
	changeServicesTable,
	changeProductsTable,
	selectProducts,
	selectServices,
	changeIsPaidStatus,
	changeIsWithoutPaymentStatus,
	changeToCashRegisterPaymentStatus,
	changeClient,
	changeContract,
	changeOrganization,
	changeStock,
	deleteSuborder,
	issueDoubleWaybill,
} = suborderPermissionTokens;

export function getRegularSuborderPermissions() {
	return [
		addProducts,
		addServices,
		deleteProducts,
		deleteServices,
		interactWithChangePriceTool,
		changeServicesTable,
		changeProductsTable,
		selectProducts,
		selectServices,
		changeIsPaidStatus,
		changeIsWithoutPaymentStatus,
		changeToCashRegisterPaymentStatus,
		changeClient,
		changeContract,
		changeOrganization,
		changeStock,
		deleteSuborder,
		issueDoubleWaybill,
	];
}

export function getSplittedRootSuborderPermissions() {
	return [selectProducts, selectServices, changeIsPaidStatus, changeIsWithoutPaymentStatus, changeToCashRegisterPaymentStatus];
}
