import { createDraftSafeSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';

const rootSelector = (state: RootState) => state;
const appSliceSelector = createDraftSafeSelector(rootSelector, (state) => state.app);

export const selectAppModeDetails = createDraftSafeSelector(appSliceSelector, (state) => state);

export const selectCalculatorButtonState = createDraftSafeSelector(appSliceSelector, (state) => {
	return {
		isMinimizedView: state.calculatorButton === 'minimized',
		isMaximizedView: state.calculatorButton === 'maximized',
		isStandardView: state.calculatorButton === 'standard',
		isClosed: state.calculatorButton === 'closed',
		state: state.calculatorButton,
	};
});
