export interface PermissionToken {
	builderTokens: (index: number) => Readonly<[string, string]>;
	checkerTokens: (index: number) => { I: string; an: string };
}

const createPermissionTokens = <T extends string>({ subjectPrefix }: { subjectPrefix: T }) => ({
	viewOrderStructure: {
		builderTokens: () => ['click', `${subjectPrefix}.structure`] as const,
		checkerTokens: () => ({ I: 'click', an: `${subjectPrefix}.structure` } as const),
	},
	changeClient: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.client`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.client` } as const),
	},
	changeOrganization: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.organization`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.organization` } as const),
	},
	changeContract: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.contract`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.contract` } as const),
	},
	changeStock: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.stock`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.stock` } as const),
	},
	changeResponsiblePerson: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.responsible`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.responsible` } as const),
	},
	changeCreatorPerson: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.creator`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.creator` } as const),
	},
	changeIsPaidStatus: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.isPaid`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.isPaid` } as const),
	},
	changeIsWithoutPaymentStatus: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.isWithoutPayment`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.isWithoutPayment` } as const),
	},
	changeToCashRegisterPaymentStatus: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.toCashRegister`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.toCashRegister` } as const),
	},
	addProducts: {
		builderTokens: (suborderIndex: number) => ['add', `${subjectPrefix}.${suborderIndex}.products`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'add', an: `${subjectPrefix}.${suborderIndex}.products` } as const),
	},
	deleteProducts: {
		builderTokens: (suborderIndex: number) => ['delete', `${subjectPrefix}.${suborderIndex}.products`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'delete', an: `${subjectPrefix}.${suborderIndex}.products` } as const),
	},
	changeProductsTable: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.products.table`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.products.table` } as const),
	},
	addServices: {
		builderTokens: (suborderIndex: number) => ['add', `${subjectPrefix}.${suborderIndex}.services`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'add', an: `${subjectPrefix}.${suborderIndex}.services` } as const),
	},
	deleteServices: {
		builderTokens: (suborderIndex: number) => ['delete', `${subjectPrefix}.${suborderIndex}.services`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'delete', an: `${subjectPrefix}.${suborderIndex}.services` } as const),
	},
	changeServicesTable: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.services.table`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.services.table` } as const),
	},
	interactWithTableColumns: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.columns`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.columns` } as const),
	},
	interactWithChangePriceTool: {
		builderTokens: (suborderIndex: number) => ['change', `${subjectPrefix}.${suborderIndex}.price`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'change', an: `${subjectPrefix}.${suborderIndex}.price` } as const),
	},
	splitOrderManually: {
		builderTokens: (suborderIndex: number) => ['click', `${subjectPrefix}.${suborderIndex}.split`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'click', an: `${subjectPrefix}.${suborderIndex}.split` } as const),
	},
	splitOrderAutomated: {
		builderTokens: (suborderIndex: number) => ['click', `${subjectPrefix}.${suborderIndex}.auto-split`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'click', an: `${subjectPrefix}.${suborderIndex}.auto-split` } as const),
	},
	issueDoubleWaybill: {
		builderTokens: (suborderIndex: number) => ['click', `${subjectPrefix}.${suborderIndex}.double-waybill`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'click', an: `${subjectPrefix}.${suborderIndex}.double-waybill` } as const),
	},
	issueBusinessOfferDocument: {
		builderTokens: (suborderIndex: number) => ['click', `${subjectPrefix}.${suborderIndex}.business-offer`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'click', an: `${subjectPrefix}.${suborderIndex}.business-offer` } as const),
	},
	issueWaybill: {
		builderTokens: (suborderIndex: number) => ['click', `${subjectPrefix}.${suborderIndex}.waybill`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'click', an: `${subjectPrefix}.${suborderIndex}.waybill` } as const),
	},
	reserveOrder: {
		builderTokens: (suborderIndex: number) => ['click', `${subjectPrefix}.${suborderIndex}.reserve`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'click', an: `${subjectPrefix}.${suborderIndex}.reserve` } as const),
	},
	saveOrder: {
		builderTokens: (suborderIndex: number) => ['click', `${subjectPrefix}.${suborderIndex}.save`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'click', an: `${subjectPrefix}.${suborderIndex}.save` } as const),
	},
	alterCreationDate: {
		builderTokens: () => ['click', `${subjectPrefix}.update-date`] as const,
		checkerTokens: () => ({ I: 'click', an: `${subjectPrefix}.update-date` } as const),
	},
	selectProducts: {
		builderTokens: (suborderIndex: number) => ['select', `${subjectPrefix}.${suborderIndex}.products`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'select', an: `${subjectPrefix}.${suborderIndex}.products` } as const),
	},
	selectServices: {
		builderTokens: (suborderIndex: number) => ['select', `${subjectPrefix}.${suborderIndex}.services`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'select', an: `${subjectPrefix}.${suborderIndex}.services` } as const),
	},
	deleteSuborder: {
		builderTokens: (suborderIndex: number) => ['delete', `${subjectPrefix}.${suborderIndex}`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'delete', an: `${subjectPrefix}.${suborderIndex}` } as const),
	},
	issueBusinessOfferDocumentSafely: {
		builderTokens: () => ['delete', `${subjectPrefix}.business-offer-safe`] as const,
		checkerTokens: () => ({ I: 'delete', an: `${subjectPrefix}.business-offer-safe` } as const),
	},
	issueWaybillFromPrintingTemplate: {
		builderTokens: (suborderIndex: number) => ['click', `${subjectPrefix}.${suborderIndex}.printing-templates`] as const,
		checkerTokens: (suborderIndex: number) => ({ I: 'click', an: `${subjectPrefix}.${suborderIndex}.printing-templates` } as const),
	},
	viewCommentDrawer: {
		builderTokens: () => ['click', `${subjectPrefix}.comment-drawer`] as const,
		checkerTokens: () => ({ I: 'click', an: `${subjectPrefix}.comment-drawer` } as const),
	},
});

export const permissionTokens = createPermissionTokens({ subjectPrefix: 'order' });
export const suborderPermissionTokens = createPermissionTokens({ subjectPrefix: 'suborder' });
