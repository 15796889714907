import { getBaseOrderPermissions } from './basePermissions';
import { permissionTokens } from './permissionTokens';

const {
	issueBusinessOfferDocumentSafely,
	changeProductsTable,
	changeServicesTable,
	addProducts,
	addServices,
	saveOrder,
	selectProducts,
	selectServices,
} = permissionTokens;

export function getNewOrderPermissions() {
	const baseTokens = getBaseOrderPermissions();

	return [
		...baseTokens,
		changeProductsTable,
		changeServicesTable,
		issueBusinessOfferDocumentSafely,
		addProducts,
		addServices,
		saveOrder,
		selectProducts,
		selectServices,
	];
}
