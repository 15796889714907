import type { Whoami } from 'models/auth';
import type { Client } from 'models/client';
import { Option } from 'models/common/options';
import type { Order } from 'models/order';
import type { OrderProduct } from 'models/product';
import type { OrderService } from 'models/service';
import { uuid } from 'utils/shared';
import { isObject } from 'utils/type-guards';

interface CreateDraftEntityPayload {
	client: Client;
	manager: Whoami;
	products?: OrderProduct[];
	services?: OrderService[];
	stock?: Option;
	organization?: Option;
	contract?: Option;
}

export const createOrderLikeEntity = ({ client, manager, organization }: CreateDraftEntityPayload): Order => {
	const user = {
		id: manager.id ?? '',
		'1c_uuid': manager?.['1c_uuid'] ?? '',
		name: manager.name ?? '',
	};
	const clientManager = client?.manager;
	const responsible = isObject(clientManager) ? clientManager : user;
	const stock = isObject(clientManager?.stock) ? clientManager.stock : { title: '', id: '' };

	const newOrder = {
		client,
		id: '',
		sum: 0,
		number: uuid(),
		status: 'calculation' as const,
		logisticsStatus: 'Очікує загрузку' as const,
		paymentStatus: 'no_payment' as const,
		isReserved: false,
		isPaid: false,
		isClosed: false,
		isWithoutPayment: false,
		type: 'regular' as const,
		note: '',
		responsible,
		manager: {
			value: clientManager?.['1c_uuid'] ?? '',
			label: clientManager?.name ?? '',
		},
		contract: client?.contracts?.[0] || {
			id: '',
			title: '',
			client: undefined,
			organization: {
				id: '',
				title: '',
			},
			expiredAt: '',
		},
		organization: {
			id: organization?.value ?? '',
			title: organization?.label ?? '',
		},
		typePrice: {
			id: '',
			title: '',
		},
		stock,
		products: [],
		services: [],
		realizations: [],
		payments: [],
		returns: [],
		doubleInvoice: null,
		createdAt: new Date(),
		date: new Date(),
		subOrders: [],
		weight: 0,
		volume: 0,
		parentId: null,
		lockedBy: null,
		lockedUntil: null,
		access: 'full' as const,
		toCashRegister: false,
	} as Order;

	return newOrder;
};
