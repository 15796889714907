import clsx from 'clsx';
import React, { forwardRef } from 'react';

import styles from './styles.module.css';
import type { AlertDialogueContentProps } from './types';

const AlertDialogueContent = ({ children, className, ...restProps }: AlertDialogueContentProps, ref: React.ForwardedRef<HTMLDivElement>) => {
	return (
		<div ref={ref} className={clsx(styles.content, className)} {...restProps}>
			{children}
		</div>
	);
};

export default forwardRef(AlertDialogueContent);
