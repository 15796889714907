import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
	generateRandomColorantsArray,
	getRandomNumber,
	paintBase,
	paintCollections,
	paintPalette,
	paintPaletteCodes,
	paintProductCodes,
	paintTare,
} from 'components/PaintToningPageComponents/lib/mockData';
import {
	ColorantCalculationRequestDTO,
	ColorantCalculationResponse,
	ColorantCalculatorDataResponse,
	PaintParametersResponse,
} from 'models/IPaintToning';
import { clientsQueryKeys } from 'services/queryKeys';
import { uuid } from 'utils/shared';

export const paintToningSliceApi = createApi({
	reducerPath: 'paint-toning',
	baseQuery: fetchBaseQuery({ baseUrl: '' }),
	tagTypes: [clientsQueryKeys.clients()],
	endpoints: (builder) => ({
		getPaintBrands: builder.query<unknown[], string>({
			queryFn: async () => {
				return {
					data: [
						{ label: 'Shtock', value: '1' },
						{ label: 'Ceresit', value: '2' },
					] as unknown[],
				};
			},
		}),
		getColorantCalculatorData: builder.query<ColorantCalculatorDataResponse, string>({
			queryFn: async () => {
				return {
					data: {
						colorCodes: paintPalette,
						tares: paintTare,
						paint: getFakePaint(),
					},
				};
			},
		}),
		getPaintParametersByBrand: builder.query<PaintParametersResponse, void>({
			queryFn: () => {
				return {
					data: {
						collection: paintCollections,
						palette: paintPaletteCodes,
						productCodes: paintProductCodes,
						base: paintBase,
					},
				};
			},
		}),
		calculateColorants: builder.mutation<ColorantCalculationResponse, ColorantCalculationRequestDTO>({
			queryFn: async (dto) => {
				const { colorCode } = dto;

				const service = {
					id: uuid(),
					service: 'Тонування штукатурки та фарби',
					colorCode: dto.colorCode,
					collection: dto.collection,
					tare: dto.tare,
					price: 2500,
					quantity: dto.quantity,
					type: 'paint_toning' as const,
				};

				const fakeDelay = (): Promise<ColorantCalculationResponse> => {
					return new Promise((res) => {
						setTimeout(() => {
							res({
								colorants: generateRandomColorantsArray(colorCode),
								toningPrice: getRandomNumber(50, 1000),
								paintBasePriceWithoutToning: getRandomNumber(200, 10000),
								image: `https://picsum.photos/id/${Math.ceil(getRandomNumber(1, 100))}/200/300`,
								service: [service],
							});
						}, 1000);
					});
				};
				const data = await fakeDelay();

				return {
					data,
				};
			},
		}),
	}),
});

export const { useGetPaintBrandsQuery, useGetPaintParametersByBrandQuery, useGetColorantCalculatorDataQuery, useCalculateColorantsMutation } =
	paintToningSliceApi;

// !!! TEMP fake
function getFakePaint() {
	return {
		parentIds: [
			'a26b699d-ee79-11ec-b88e-95373851a7ce',
			'e118f23c-e50c-11ec-b88d-f99a7ac3eec6',
			'b970abbd-f8f8-11ec-b890-f39b65473376',
			'576d9c4c-f8f9-11ec-b890-f39b65473376',
		],
		id: 'f5b8764b-4563-11ed-b8a3-b35517a2197b',
		parentId: '4e66213b-e3e4-11eb-8120-9f86579df20b',
		brand: 'SHTOCK',
		// eslint-disable-next-line quotes
		title: "SHTOCK фарба інтер'єрна акрилова F9 Glade (база A) , 14 кг",
		price: 2600,
		quantity: '19',
		pickedAmount: 1,
		inReserve: '',
		weight: '14',
		volume: '0',
		coefficient: '1',
		measureUnit: {
			label: 'шт',
			value: 'шт',
		},
	};
}
