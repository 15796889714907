import { z } from 'zod';

export const PrintingTemplateSchema = z.object({
	id: z.string(),
	preview: z.string().nullish().default('/images/image-not-available.png'),
	title: z.string(),
	type: z.string(),
});

export const GeneratedPrintingTemplateSchema = z.object({
	id: z.string(),
	url: z.string(),
});

export type PrintingTemplate = z.infer<typeof PrintingTemplateSchema>;
export type GeneratedPrintingTemplate = ArrayBuffer;
