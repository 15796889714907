import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

interface UseForbidGlobalScrollProps {
	target?: HTMLElement;
}

export const useForbidGlobalScroll = (config?: UseForbidGlobalScrollProps) => {
	const target = config?.target || document.body;

	useLayoutEffect(() => {
		if (target.classList.contains('no-scroll')) return;

		target.classList.add('no-scroll');

		return () => {
			target.classList.remove('no-scroll');
		};
	}, [target]);
};

export const useLazyForbidGlobalScroll = (config?: UseForbidGlobalScrollProps) => {
	const target = config?.target || document.documentElement;
	const targetRef = useRef(target);

	const disableScroll = useCallback(
		(newTarget?: UseForbidGlobalScrollProps['target']) => {
			const resolvedTarget = newTarget || target;
			targetRef.current = resolvedTarget;

			if (resolvedTarget.classList.contains('no-scroll')) return;

			resolvedTarget.classList.add('no-scroll');
		},
		[target],
	);
	const enableScroll = useCallback(() => {
		if (!targetRef.current?.classList?.contains('no-scroll')) return;

		targetRef.current?.classList?.remove('no-scroll');
	}, [targetRef.current]);

	useEffect(() => {
		return () => {
			targetRef.current?.classList?.remove('no-scroll');
		};
	}, [targetRef.current]);

	return {
		disableScroll,
		enableScroll,
	};
};
