import type { AppDispatch } from 'store';
import { authSliceApi } from 'store/reducers/auth/authSliceApi';
import { clientsSliceApi } from 'store/reducers/clients/clientsSliceApi';
import { filterTemplatesSliceApi } from 'store/reducers/filterTempates/filterTemplatesSliceApi';
import { ordersSliceApi } from 'store/reducers/orders/ordersSliceApi';
import { usersSliceApi } from 'store/reducers/users/usersSliceApi';

const apiList = [ordersSliceApi, clientsSliceApi, filterTemplatesSliceApi, authSliceApi, usersSliceApi];

export const resetGlobalApiState = (dispatch: AppDispatch) => {
	apiList.forEach((api) => {
		dispatch(api.util.resetApiState());
	});
};
