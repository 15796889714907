import clsx from 'clsx';
import ClickAwayListener from 'components/ClickAwayListener';
import { useLazyForbidGlobalScroll } from 'hooks/useForbidGlobalScroll';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

import styles from './styles.module.css';
import type { IProps } from './types';

const Modal: React.FC<IProps> = ({
	open,
	className,
	children,
	onClose,
	minWidth = '300px',
	minHeight = '302px',
	maxHeight = 'auto',
	maxWidth = '314px',
	width = '100%',
	noAwayClick = false,
	stackable = false,
	style,
}) => {
	const { disableScroll, enableScroll } = useLazyForbidGlobalScroll();

	useEffect(() => {
		if (open) {
			disableScroll();
		} else {
			enableScroll();
		}

		return enableScroll;
	}, [open]);

	const closeHandler = noAwayClick ? () => {} : onClose;

	const modalComponent = (
		<div
			className={clsx(styles.modalOverlay, className)}
			onClick={(e) => {
				if (noAwayClick) e.stopPropagation();
			}}
		>
			<ClickAwayListener onClickAway={closeHandler} style={style}>
				<div className={styles.modal} style={{ maxWidth, minHeight, maxHeight, width, minWidth, height: '100%' }}>
					{children}
				</div>
			</ClickAwayListener>
		</div>
	);

	if (!open) return null;

	return stackable ? modalComponent : <>{createPortal(modalComponent, document.querySelector('#modal-root')!)}</>;
};

export default Modal;
