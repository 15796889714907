import { orderUrlUtil, ROUTES_URLS } from 'const';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { prepareUrl } from 'utils/shared';
import { isNumber } from 'utils/type-guards';

interface RouterPayload {
	isNew?: boolean;
	index?: number;
}

export const useOrderRouter = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = useParams<{ id: string }>();

	const isNewOrder = location.pathname.startsWith('/' + ROUTES_URLS.ORDER_NEW_ROOT + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT);

	return {
		toOrder: (payload?: RouterPayload) => {
			const url =
				payload?.isNew || isNewOrder ? prepareUrl(orderUrlUtil.getFullNewOrderUrl()) : prepareUrl(orderUrlUtil.getFullPreviewUrl(), { id });
			navigate(url);
		},
		toCertainOrder: (orderId: string, config?: { replace?: boolean }) => {
			const url = prepareUrl(orderUrlUtil.getFullPreviewUrl(), { id: orderId });
			navigate(url, config);
		},
		toAddProducts: (payload?: RouterPayload & { searchParams?: string }) => {
			let url =
				payload?.isNew || isNewOrder
					? prepareUrl(orderUrlUtil.getFullNewOrderSetProductsUrl())
					: prepareUrl(orderUrlUtil.getFullPreviewSetProductsUrl(), { id });

			if (isNumber(payload?.index)) {
				url += `?from=${payload.index}`;
			}

			if (payload?.searchParams) {
				const connector = url.includes('?') ? '&' : '?';
				url += `${connector}${payload.searchParams}`;
			}

			navigate(url);
		},
		toSplitOrder: (index?: number) => {
			let url = isNewOrder
				? prepareUrl(orderUrlUtil.getFullNewOrderSplitOrderUrl())
				: prepareUrl(orderUrlUtil.getFullPreviewSplitOrderUrl(), { id });

			if (isNumber(index)) {
				url += `?from=${index}`;
			}

			navigate(url);
		},
		toAssignClient: (config?: { searchParams?: string }) => {
			let url = prepareUrl(orderUrlUtil.getFullNewOrderSetClientUrl());

			if (config?.searchParams) {
				url += config.searchParams;
			}

			navigate(url);
		},
		toClient: (config: { isNew?: boolean; clientId: string; orderId?: string; from?: string }) => {
			let url = prepareUrl(orderUrlUtil.getFullPreviewClientCardUrl(), { id: config.orderId, clientId: config.clientId });
			let state = null;

			if (config?.isNew || isNewOrder) {
				url = prepareUrl(orderUrlUtil.getFullNewOrderClientCardUrl(), { clientId: config.clientId });
			}

			if (config.from !== undefined) {
				state = {
					url: location.pathname + '?from=' + config.from,
				};
			}

			navigate(url, {
				state,
			});
		},
	};
};
