import type { AutoScrollOptions } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';

export const modifiers = [restrictToHorizontalAxis];
export const autoscrollOptions: AutoScrollOptions = {
	threshold: {
		x: 0.2,
		y: 0,
	},
};
