import 'static/styles/style.css';

import clsx from 'clsx';
import React, { forwardRef, useEffect, useState } from 'react';

import styles from './styles.module.css';
import { IProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Button = (
	{ id, text, onClick, className, icon, variant, background, active, type = 'button', disableClassName, hoverBg, ...restProps }: IProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) => {
	const [activeState, setActiveState] = useState(false);

	useEffect(() => {
		setActiveState(active);
	}, [active]);

	const cssProperties = { '--button-hover-bg': hoverBg } as React.CSSProperties;

	if (variant === 'default') {
		return (
			<button
				ref={ref}
				id={id}
				className={clsx(styles.primaryButton, 'text-md-medium', className, restProps.disabled && disableClassName)}
				onClick={onClick}
				type={type}
				style={{ backgroundColor: background, borderColor: background, ...cssProperties } as React.CSSProperties}
				{...restProps}
			>
				{!!icon && icon} {text}
			</button>
		);
	} else if (variant === 'rounded') {
		return (
			<button
				ref={ref}
				id={id}
				style={{ backgroundColor: background, ...cssProperties }}
				className={clsx(styles.roundedButton, 'text-md-medium', className, restProps.disabled && disableClassName)}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{!!icon && icon} {text}
			</button>
		);
	} else if (variant === 'small') {
		return (
			<button
				ref={ref}
				className={clsx(styles.smallButton, 'text-sm-regular', className, {
					[styles.smallButton]: activeState,
					[styles.smallActive]: activeState,
					'text-sm-semibold': activeState,
				})}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{!!icon && icon} {text}
			</button>
		);
	} else if (variant === 'bordered') {
		return (
			<button
				ref={ref}
				style={{ padding: !text && '10px 10px' }}
				className={clsx(styles.borderedButton, 'text-sm-regular', className, {
					[styles.borderedButton]: activeState,
					[styles.smallActive]: activeState,
					'text-sm-semibold': activeState,
				})}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{!!icon && icon} {text}
			</button>
		);
	} else if (variant === 'smallRounded') {
		return (
			<button
				ref={ref}
				id={id}
				className={clsx(styles.smallRounded, 'text-sm-regular', className)}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{!!icon && icon} {text}
			</button>
		);
	} else if (variant === 'inverse') {
		return (
			<button
				ref={ref}
				id={id}
				className={clsx(styles.inverse, 'text-sm-regular', className, { [styles.inverseActive]: activeState })}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{!!icon && icon} {text}
			</button>
		);
	} else if (variant === 'inverseBordered') {
		return (
			<button
				ref={ref}
				id={id}
				className={clsx(styles.inverseBordered, 'text-sm-regular', className)}
				onClick={onClick}
				type={type}
				{...restProps}
			>
				{!!icon && icon} {text}
			</button>
		);
	}
	return null;
};

export default forwardRef(Button);
