import { OrderClientSchema } from 'models/client';
import {
	ClientIdSchema,
	ContractIdSchema,
	OrderIdSchema,
	OrganizationIdSchema,
	ResponsibleIdSchema,
	StockIdSchema,
	TransactionEntityIdSchema,
	TypePriceIdSchema,
} from 'models/common/uuid';
import { ContractSchema } from 'models/contract';
import { EmployeeOrganizationSchema, OrderCreatorEmployee } from 'models/employee';
import { TypePriceSchema } from 'models/price';
import { CreateProductSchema, OrderProductSchema, UpdateProductSchema } from 'models/product';
import { CreateServiceSchema, OrderServiceSchema, UpdateServiceSchema } from 'models/service';
import { StockSchema } from 'models/stock';
import { z } from 'zod';

const OrderTypeSchema = z
	.union([z.literal('regular'), z.literal('paint_toning'), z.literal('double_invoice')])
	.nullable()
	.default('regular');
export const OrderStatusSchema = z
	.union([z.literal('calculation'), z.literal('reserve'), z.literal('partially_shipped'), z.literal('shipped'), z.literal('closed')])
	.default('calculation');

export const PaymentStatus = z.union([z.literal('no_payment'), z.literal('partially_payment'), z.literal('paid'), z.literal('overpayment')]);

const OrderLogisticsStatus = z.preprocess((input) => {
	if (!input) return 'Очікує загрузку';
	if (input) return 'Відвантажено';

	return input;
}, z.union([z.literal('Очікує загрузку'), z.literal('Відвантажено')]));

export const TransactionEntitySchema = z.object({
	id: TransactionEntityIdSchema,
	number: z.string().nullish(),
	title: z.string().nullish(),
	date: z.any().nullish(),
	sum: z.string().nullish(),
});

const BaseOrderSchema = z.object({
	id: OrderIdSchema,
	sum: z.coerce.number(),
	number: z.string().nullable(),
	appNumber: z.string().nullable(),
	status: OrderStatusSchema,
	paymentStatus: PaymentStatus,
	logisticsStatus: OrderLogisticsStatus,
	type: OrderTypeSchema.nullable(),
	note: z.string().optional().nullable(),
	date: z.any(),
	createdAt: z.coerce.date(),
	duplicateFromId: z.string().nullish(),
});

// ****** CRUD ********//
// -- CREATE
export const CreateOrderSchema = BaseOrderSchema.pick({
	note: true,
	type: true,
}).extend({
	isPaid: z.number(),
	isWithoutPayment: z.number(),
	toCashRegister: z.boolean(),
	clientId: ClientIdSchema,
	contractId: ContractIdSchema,
	responsibleId: ResponsibleIdSchema,
	organizationId: OrganizationIdSchema,
	stockId: StockIdSchema,
	typePriceId: TypePriceIdSchema,
	products: z.array(CreateProductSchema),
	services: z.array(CreateServiceSchema),
	parentId: OrderIdSchema.nullable().optional(),
});

// -- READ
const BaseDetailedSuborderSchema = BaseOrderSchema.extend({
	weight: z.number(),
	volume: z.number(),
	isReserved: z.coerce.boolean(),
	isPaid: z.coerce.boolean(),
	isWithoutPayment: z.coerce.boolean(),
	toCashRegister: z.coerce.boolean(),
	isClosed: z.coerce.boolean(),
	client: OrderClientSchema.nullish(),
	responsible: OrderCreatorEmployee,
	contract: ContractSchema.omit({ expiredAt: true }).optional(),
	organization: EmployeeOrganizationSchema,
	typePrice: TypePriceSchema,
	stock: StockSchema,
	products: z.array(OrderProductSchema),
	services: z.array(OrderServiceSchema),
	realizations: z.array(TransactionEntitySchema),
	payments: z.array(TransactionEntitySchema),
	returns: z.array(TransactionEntitySchema),
	subOrders: z.array(z.any()),
	parentId: OrderIdSchema.nullable(),
	creator: z
		.object({
			id: z.number().nullish(),
			'1c_uuid': z.string().nullish(),
			name: z.string().nullish(),
		})
		.nullish(),
	lockedBy: z
		.object({
			id: z.number().nullish(),
			'1c_uuid': z.string().nullish(),
			name: z.string().nullish(),
		})
		.nullish(),
	lockedUntil: z.any().nullish(),
	access: z.union([z.literal('full'), z.literal('readonly')]).nullish(),
	doubleInvoice: z.string().nullish(),
});

export const SuborderSchema = BaseDetailedSuborderSchema.extend({});

const BaseDetailedOrderSchema = BaseOrderSchema.extend({
	weight: z.number(),
	volume: z.number(),
	isReserved: z.coerce.boolean(),
	isPaid: z.coerce.boolean(),
	isWithoutPayment: z.coerce.boolean(),
	isClosed: z.coerce.boolean(),
	toCashRegister: z.coerce.boolean(),
	client: OrderClientSchema.nullable(),
	responsible: OrderCreatorEmployee.nullable(),
	contract: ContractSchema.omit({ expiredAt: true }).optional(),
	organization: EmployeeOrganizationSchema,
	typePrice: TypePriceSchema,
	stock: StockSchema,
	products: z.array(OrderProductSchema),
	services: z.array(OrderServiceSchema),
	realizations: z.array(TransactionEntitySchema),
	payments: z.array(TransactionEntitySchema),
	returns: z.array(TransactionEntitySchema),
	subOrders: SuborderSchema.array(),
	parentId: OrderIdSchema.nullable(),
	creator: z
		.object({
			id: z.number().nullable(),
			'1c_uuid': z.string().nullable(),
			name: z.string().nullable(),
		})
		.nullish(),
	lockedBy: z
		.object({
			id: z.number(),
			'1c_uuid': z.string(),
			name: z.string(),
		})
		.nullish(),
	lockedUntil: z.any().nullish(),
	access: z.union([z.literal('full'), z.literal('readonly')]).nullish(),
	isOfflineCreated: z.boolean().optional().nullable().default(false),
	doubleInvoice: z.string().nullish(),
});

export const OrderSchema = BaseDetailedOrderSchema.extend({});

export const OrderPreviewSchema = BaseOrderSchema.omit({ appNumber: true }).extend({
	client: z.string().nullable(),
	stock: z.string(),
	parentId: z.string().nullable(),
	contract: z.string().optional(),
	responsible: z.string().nullable(),
	note: z.string().optional().nullable(),
	appNumber: z.string().optional().nullable(),
	isOfflineCreated: z.boolean().optional().nullable().default(false),
});

// -- UPDATE
export const UpdateOrderSchema = CreateOrderSchema.pick({
	note: true,
	clientId: true,
	contractId: true,
	responsibleId: true,
	organizationId: true,
	stockId: true,
	typePriceId: true,
}).extend({
	id: OrderIdSchema,
	isPaid: z.number(),
	isWithoutPayment: z.number(),
	isReserved: z.number(),
	toCashRegister: z.boolean(),
	products: z.array(UpdateProductSchema),
	services: z.array(UpdateServiceSchema),
	parentId: OrderIdSchema.nullable().optional(),
	date: z.date().optional(),
});

export type OrderUpdate = z.infer<typeof UpdateOrderSchema>;
export type OrderCreate = z.infer<typeof CreateOrderSchema>;
export type Order = z.infer<typeof OrderSchema>;
export type Suborder = z.infer<typeof SuborderSchema>;
export type OrderPreview = z.infer<typeof OrderPreviewSchema>;
export type TransactionEntity = z.infer<typeof TransactionEntitySchema>;
