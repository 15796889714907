import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ICreateOrderSliceState } from './types';

const initialState = {
	productBeingPicked: null,
	mode: 'create',
	view: 'popup',
	cart: {},
	comment: '',
	orderLikeEntity: null,
} as ICreateOrderSliceState;

const createOrderSlice = createSlice({
	name: 'createOrder',
	initialState,
	reducers: {
		setProductBeingPicked: (
			state,
			action: PayloadAction<{
				product: ICreateOrderSliceState['productBeingPicked'];
				mode: ICreateOrderSliceState['mode'];
				view?: ICreateOrderSliceState['view'];
			}>,
		) => {
			state.productBeingPicked = action.payload.product;
			state.mode = action.payload.mode;
			state.view = action.payload.view ?? 'popup';
		},
		clearProductBeingPicked: (state) => {
			state.productBeingPicked = null;
			state.mode = 'create';
			state.view = 'popup';
		},
		setOrderLikeEntity: (state, action: PayloadAction<ICreateOrderSliceState['orderLikeEntity']>) => {
			state.orderLikeEntity = action.payload;
		},
	},
});

export const createOrderSliceActions = createOrderSlice.actions;
export default createOrderSlice.reducer;
