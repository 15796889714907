import clsx from 'clsx';
import { breakPoints } from 'const';
import { DOTS, usePagination } from 'hooks/usePagination';
import React from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { Link, useSearchParams } from 'react-router-dom';
import { ReactComponent as Arrow } from 'static/images/arrow-left.svg';

import styles from './style.module.css';
import type { IProps } from './types';

const Pagination: React.FC<IProps> = ({ pagesCount, siblingCount = 2 }) => {
	const [searchParams] = useSearchParams();
	const pageParam = 'page';
	const currentPage = Number(searchParams.get(pageParam) || 1);

	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });
	const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px)` });
	const siblings = isMobile ? 0 : isTablet ? 1 : siblingCount;

	const pages = usePagination({
		currentPage: currentPage,
		pagesCount,
		siblingCount: siblings,
		skip: isMobile,
	});

	const previousQuery = new URLSearchParams(searchParams);
	previousQuery.set(pageParam, String(currentPage - 1));

	const nextQuery = new URLSearchParams(searchParams);
	nextQuery.set(pageParam, String(currentPage + 1));
	const pageChange = new URLSearchParams(searchParams);
	const hasPages = !!pages && pages.length > 1;

	const lastPage = pages && pages[pages.length - 1];
	const isPreviousButtonDisabled = !hasPages || currentPage === 1;
	const isNextButtonDisabled = !hasPages || currentPage >= Number(lastPage);

	return (
		<div data-pagination className={styles.paginationWrapper}>
			<Link
				to={`?${previousQuery.toString()}`}
				className={clsx(styles.navigationLink, 'text-sm-medium', {
					[styles.outlinedControlButton]: isMobile,
					[styles.disabled]: isPreviousButtonDisabled,
				})}
			>
				<Arrow style={{ stroke: '#475467' }} />
				<MediaQuery minWidth={breakPoints.MOBILE}>Назад</MediaQuery>
			</Link>

			<div key={currentPage} className={styles.pageNumbers}>
				{isMobile && (
					<p className={clsx('text-sm-regular', 'color-grey-700')}>
						<span>Сторінка</span>&nbsp;
						<span className={clsx('text-sm-medium')}>{currentPage}</span>&nbsp;<span>з</span>&nbsp;
						<span className={clsx('text-sm-medium')}>{pagesCount}</span>
					</p>
				)}

				{!isMobile &&
					pages.map((page, idx) => {
						if (page == DOTS) {
							return (
								<span key={page + idx} className={clsx('text-sm-medium')}>
									<span className={styles.pageNumber}>{page}</span>
								</span>
							);
						}

						pageChange.set(pageParam, String(page));

						return (
							<Link
								key={page}
								to={`?${pageChange.toString()}`}
								className={clsx('text-sm-medium', {
									[styles.currentPage]: currentPage === Number(page),
									[styles.disabled]: isPreviousButtonDisabled && isNextButtonDisabled,
								})}
							>
								<span className={styles.pageNumber} style={{ backgroundColor: currentPage === Number(page) && '#f9fafb' }}>
									{page}
								</span>
							</Link>
						);
					})}
			</div>

			<Link
				to={`?${nextQuery.toString()}`}
				className={clsx(styles.navigationLink, 'text-sm-medium', {
					[styles.outlinedControlButton]: isMobile,
					[styles.disabled]: isNextButtonDisabled,
				})}
			>
				<MediaQuery minWidth={breakPoints.MOBILE}>Вперед</MediaQuery>

				<Arrow style={{ stroke: '#475467' }} className={styles.nextArrow} />
			</Link>
		</div>
	);
};

export default Pagination;
