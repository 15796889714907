/* eslint-disable no-console */
import { persistorStorage } from 'store/config/persistor.config';

const VERSION_KEY = 'UNIT_B_APP';
const VERSION_FILE_URL = '/version.json';

const getMajorVersion = (version: string | null) => version?.split('.')[0] ?? '0';

export async function checkAppVersion() {
	try {
		const response = await fetch(VERSION_FILE_URL);
		if (!response.ok) {
			console.error('Failed to fetch version file');
			return;
		}

		const { version: currentVersion } = await response.json();
		const storedVersion = localStorage.getItem(VERSION_KEY);

		const currentMajor = getMajorVersion(currentVersion);
		const storedMajor = getMajorVersion(storedVersion);

		// Check if the major version has changed
		if (storedVersion !== currentVersion) {
			if (currentMajor !== storedMajor) {
				persistorStorage.removeItem('persist:root').catch(console.log);
				localStorage.clear();

				console.log(`Storage cleared due to major version change: ${storedVersion} → ${currentVersion}`);
			} else {
				console.log(`Version updated to ${currentVersion} without clearing storage`);
			}

			localStorage.setItem(VERSION_KEY, currentVersion);
		} else {
			console.log(`App version is already up-to-date: ${currentVersion}`);
		}
	} catch (error) {
		console.log('Error checking version:', error);
	}
}
