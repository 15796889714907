import { BroadcastChannel } from 'broadcast-channel';
import { NotificationItem } from 'components/Notifications';
import { SKIP_WAITING_MESSAGE } from 'lib/service-worker/messages.const';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { register as registerServiceWorker } from 'serviceWorkerRegistration';
import { logger } from 'utils/logger';

type OfflineModeContext = {
	hasSwUpdate: boolean;
	applySwUpdates: () => Promise<void>;
	getNotifications: () => NotificationItem[];
};
const Context = createContext<OfflineModeContext | null>(null);

export const useOfflineMode = () => {
	const ctx = useContext(Context);
	if (!ctx) throw new Error('useOfflineMode should be used within OfflineModeContext');

	return ctx;
};

const bc = new BroadcastChannel('sw-update-channel');

type SwState = 'has-update' | 'has-error' | 'idle';
const CHECK_FOR_UPDATE_FREQUENCY = 15 * 1000;

export const OfflineModeProvider = ({ children }: React.PropsWithChildren) => {
	const [waitingWorker, setWaitingWorker] = useState<SwState>('idle');
	const intervalId = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		bc.onmessage = (event) => {
			if (event?.data?.type === 'SW_UPDATE_AVAILABLE') {
				setWaitingWorker('has-update');
			}
		};

		return () => {
			bc.close();
		};
	}, []);

	useEffect(() => {
		if (waitingWorker === 'has-update') {
			bc.postMessage({ type: 'SW_UPDATE_AVAILABLE' });
		}
	}, [waitingWorker]);

	useEffect(() => {
		const ctrl = new AbortController();
		const { signal } = ctrl;

		(async () => {
			const registration = await registerServiceWorker();
			if (!registration) return;

			if (!registration.installing && registration.waiting) {
				logger.info('New content available. Refresh to update. 0');
				setWaitingWorker('has-update');
			}

			registration.onupdatefound = () => {
				const installingWorker = registration.installing;

				installingWorker.onstatechange = () => {
					switch (installingWorker.state) {
						case 'installed':
							if (navigator.serviceWorker.controller) {
								logger.info('New content available. Refresh to update. 1');
								setWaitingWorker('has-update');
							} else {
								logger.info('Content cached for offline use.');
							}
							break;
						case 'activating':
							logger.info('New service worker is activating...');
							break;
						case 'activated':
							logger.info('Service worker activated. Refreshing...');
							break;
						case 'redundant':
							logger.error('Service worker update failed.');
							break;
					}
				};
			};

			navigator.serviceWorker.addEventListener(
				'controllerchange',
				() => {
					logger.info('New service worker is now controlling the page. Reloading...');
					if (intervalId.current !== null) {
						clearInterval(intervalId.current);
					}
					ctrl.abort();
					window.location.reload();
				},
				{ signal },
			);

			intervalId.current = setInterval(() => {
				logger.info('Checking for service worker updates...');
				registration.update().catch(() => {
					logger.info('Failed to fetch service worker update. Server might be unreachable.');
				});
			}, CHECK_FOR_UPDATE_FREQUENCY);
		})();

		return () => {
			ctrl.abort();
			if (intervalId.current !== null) {
				clearInterval(intervalId.current);
			}
		};
	}, []);

	const applySwUpdates = useCallback(async () => {
		await navigator.serviceWorker.getRegistration().then((registration) => {
			if (!registration?.waiting) return;

			registration.waiting.postMessage({ type: SKIP_WAITING_MESSAGE });
		});
	}, [waitingWorker]);

	const getNotifications = useCallback(() => {
		return waitingWorker === 'has-update' ? ([{ onAction: applySwUpdates, type: 'new-release' }] as NotificationItem[]) : [];
	}, [waitingWorker, applySwUpdates]);

	const ctx = useMemo(() => {
		return {
			hasSwUpdate: waitingWorker === 'has-update',
			applySwUpdates,
			getNotifications,
		};
	}, [waitingWorker, applySwUpdates, getNotifications]);

	return <Context.Provider value={ctx}>{children}</Context.Provider>;
};
