import { getBaseOrderPermissions } from './basePermissions';
import { permissionTokens } from './permissionTokens';

const {
	viewOrderStructure,
	splitOrderAutomated,
	splitOrderManually,
	reserveOrder,
	saveOrder,
	issueBusinessOfferDocument,
	issueWaybillFromPrintingTemplate,
	alterCreationDate,
	issueDoubleWaybill,
	issueWaybill,
	deleteSuborder,
	changeProductsTable,
	changeServicesTable,
	addProducts,
	addServices,
	selectProducts,
	selectServices,
} = permissionTokens;

const sharedPermissions = [viewOrderStructure, saveOrder];

export function getSplittedOrderPermissions() {
	const basePermissions = getBaseOrderPermissions();

	return [
		...basePermissions,
		...sharedPermissions,
		splitOrderManually,
		issueWaybillFromPrintingTemplate,
		issueBusinessOfferDocument,
		issueWaybill,
		alterCreationDate,
	];
}
export function getRegularOrderPermissions() {
	const basePermissions = getBaseOrderPermissions();

	return [
		...basePermissions,
		...sharedPermissions,
		splitOrderAutomated,
		splitOrderManually,
		reserveOrder,
		changeProductsTable,
		changeServicesTable,
		issueBusinessOfferDocument,
		issueWaybillFromPrintingTemplate,
		issueDoubleWaybill,
		alterCreationDate,
		addProducts,
		addServices,
		selectProducts,
		selectServices,
	];
}

export function getDeprecatedAsRootOrderViewPermissions() {
	return [splitOrderAutomated, splitOrderManually, issueDoubleWaybill];
}

export function getOfflineModeDeprecatedPermissionsPermissions() {
	return [
		reserveOrder,
		splitOrderAutomated,
		splitOrderManually,
		issueDoubleWaybill,
		alterCreationDate,
		issueWaybill,
		issueBusinessOfferDocument,
		issueWaybillFromPrintingTemplate,
	];
}
export function getAsDoubledWayBillOrderViewDeprecatedPermissions() {
	return [reserveOrder, issueDoubleWaybill, alterCreationDate];
}

export function getReservedOrderPermissions() {
	const basePermissions = getBaseOrderPermissions();

	return [
		...basePermissions,
		...sharedPermissions,
		issueBusinessOfferDocument,
		issueWaybillFromPrintingTemplate,
		changeProductsTable,
		changeServicesTable,
		issueWaybill,
		addProducts,
		addServices,
		reserveOrder,
	];
}
export function getProcessedOrderPermissions() {
	return [viewOrderStructure, issueWaybillFromPrintingTemplate];
}

export function getReadonlyOrderPermissions() {
	return [viewOrderStructure];
}

export function getSuborderDeprecatedPermissions() {
	return [deleteSuborder];
}
