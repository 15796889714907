import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useClickOutside } from 'hooks/useClickOutside';
import { useForbidGlobalScroll } from 'hooks/useForbidGlobalScroll';
import React from 'react';

import styles from './styles.module.css';
import type { AlertDialogueProps } from './types';

export const AnimatedAlertDialogue: React.FC<AlertDialogueProps> = ({ children, className, backdropClassName, onClick, onOutsideClick }) => {
	const ref = useClickOutside<HTMLDivElement>(onOutsideClick);

	useForbidGlobalScroll({ target: document.body });

	return (
		<motion.div
			onClick={onClick}
			className={clsx(styles.backdrop, backdropClassName)}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{
				ease: 'easeInOut',
			}}
		>
			<motion.div
				ref={ref}
				className={clsx(styles.dialogue, className)}
				initial={{ x: '-100vw', skew: 20 }}
				animate={{ x: 0, skew: 0 }}
				exit={{ x: '-100vw', skew: 0 }}
				transition={{
					ease: 'easeInOut',
				}}
			>
				{children}
			</motion.div>
		</motion.div>
	);
};
