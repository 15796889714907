import { logger } from 'utils/logger';

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.0/8 are considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export function register() {
	if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
		// The URL constructor is available in all browsers that support SW.
		const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

		if (publicUrl.origin !== window.location.origin) {
			// Our service worker won't work if PUBLIC_URL is on a different origin
			// from what our page is served on. This might happen if a CDN is used to
			// serve assets; see https://github.com/facebook/create-react-app/issues/2374
			return;
		}

		const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

		if (isLocalhost) {
			// This is running on localhost. Let's check if a service worker still exists or not.

			// Add some additional logging to localhost, pointing developers to the
			// service worker/PWA documentation.
			navigator.serviceWorker.ready.then(() => {
				logger.info('This web app is being served cache-first by a service worker. To learn more, visit https://bit.ly/CRA-PWA');
			});
			return checkValidServiceWorker(swUrl);
		} else {
			// Is not localhost. Just register service worker
			return registerValidSW(swUrl);
		}
	}
}

// let intervalId: NodeJS.Timeout = null;
// const checkForUpdateInterval = Number(process.env.SW_CHECK_FOR_UPDATE_INTERVAL ?? 1000 * 60);

function registerValidSW(swUrl) {
	return navigator.serviceWorker
		.register(swUrl, {
			scope: '/',
		})
		.then((registration) => {
			return registration;
		})
		.catch((error) => {
			logger.error('Error during service worker registration:', error);
		});
}

function checkValidServiceWorker(swUrl) {
	// Check if the service worker can be found. If it can't reload the page.
	return fetch(swUrl, {
		headers: { 'Service-Worker': 'script' },
	})
		.then((response) => {
			// Ensure service worker exists, and that we really are getting a JS file.
			const contentType = response.headers.get('content-type');
			if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
				// No service worker found. Probably a different app. Reload the page.
				return navigator.serviceWorker.ready.then((registration) => {
					registration.unregister().then(() => {
						window.location.reload();
					});
				});
			} else {
				// Service worker found. Proceed as normal.
				return registerValidSW(swUrl);
			}
		})
		.catch(() => {
			logger.info('No internet connection found. App is running in offline mode.');
		});
}

export function unregister() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready
			.then((registration) => {
				registration.unregister();
			})
			.catch((error) => {
				logger.error(error.message);
			});
	}
}
