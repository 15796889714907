import { OptionSchema, ServerSideOptionSchema } from 'models/common/options';
import { PhoneSchema } from 'models/common/phones';
import { standardizeOption } from 'models/common/preprocess';
import { ClientIdSchema, ClientManagerIdSchema, SegmentIdSchema } from 'models/common/uuid';
import { ContractSchema } from 'models/contract';
import { z } from 'zod';

import { IndividualSegmentSchema, LegalSegmentSchema, SegmentSchema } from './segment';
import { withStandardizedName } from './utils';

const ClientNameSchema = z.object({ name: z.string() });
export const ClientOptionSchema = standardizeOption<typeof OptionSchema, { id: string; name: string; organizationName?: string }>(
	OptionSchema,
	({ id, name, organizationName }) => ({
		label: organizationName || name || '',
		value: id,
	}),
);

const ClientManagerSchema = z.object({
	id: ClientManagerIdSchema,
	'1c_uuid': z.string().min(1),
	name: z.string().min(1),
});
const ClientPaymentStatisticSchema = z.object({
	availableSum: z.number(),
	limit: z.number(),
	debt: z.number(),
});
const ClientPaymentDetailSchema = z.object({
	sum: z.number(),
	amount: z.number(),
	fromAmount: z.number(),
});
const ClientStatusSchema = z.union([z.literal('green'), z.literal('white'), z.literal('yellow'), z.literal('red')]);

const BaseClientSchema = z.object({
	id: ClientIdSchema,
	email: z.string().nullable(),
	code: z.string().nullish(),
	avatar: z.string().nullish(),
});

export const OrderClientSchema = BaseClientSchema.extend({
	name: z.string().nullish(),
	organizationName: z.string().nullish(),
	manager: z
		.object({
			id: z.any().nullish(),
			name: z.string().nullish(),
		})
		.nullish(),
});
export const ContractClientSchema = BaseClientSchema;
export const ContractOptionSchema = standardizeOption<typeof OptionSchema, { title: string; id: string }>(OptionSchema, ({ title, id }) => ({
	value: id,
	label: title,
}));
const BaseIndividualClientSchema = z.object({
	segment: IndividualSegmentSchema.nullable(),
	ipnCode: z.string(),
});
const BaseLegalClientSchema = z.object({
	segment: LegalSegmentSchema.nullable(),
	edrpouCode: z.string(),
	ipnCode: z.string().optional().nullable(),
	name: z.string().optional().nullable(),
	organizationName: z.string().optional().nullable(),
});

const BaseClientPreviewSchema = BaseClientSchema.extend({
	createdAt: z.string().nullable(),
	lastDeal: z.string().nullable(),
	ordersCount: z.coerce.number(),
	segment: SegmentSchema.nullable(),
	manager: ClientManagerSchema.nullable(),
	activities: z.array(ServerSideOptionSchema),
	limit: ClientPaymentStatisticSchema.or(z.number()).optional(),
	status: ClientStatusSchema.optional(),
	comment: z.string().nullish(),
});

// Preview schemas are used in lists
const IndividualClientPreviewSchema = withStandardizedName(
	BaseClientPreviewSchema.omit({ segment: true }).merge(BaseIndividualClientSchema).merge(ClientNameSchema),
);
const LegalClientPreviewSchema = withStandardizedName(
	BaseClientPreviewSchema.omit({ segment: true }).merge(BaseLegalClientSchema).merge(ClientNameSchema),
);
const ClientPreviewSchema = IndividualClientPreviewSchema.or(LegalClientPreviewSchema);

const BaseCreateClientSchema = BaseClientSchema.omit({ id: true, code: true }).extend({
	phones: z.array(PhoneSchema),
	passport: z.string().nullish(),
	address: z.string().nullish(),
	segmentId: SegmentIdSchema,
	managerId: z.string(),
	comment: z.string().nullish(),
	activities: z.array(z.string()),
});
const CreateIndividualClientSchema = BaseCreateClientSchema.merge(BaseIndividualClientSchema);
const CreateLegalClientSchema = BaseCreateClientSchema.merge(BaseLegalClientSchema);
const CreateClientSchema = CreateIndividualClientSchema.or(CreateLegalClientSchema);

const BaseDetailedClientSchema = BaseCreateClientSchema.omit({
	segmentId: true,
	managerId: true,
	email: true,
	phones: true,
}).extend({
	manager: z
		.object({
			'1c_uuid': z.string().optional(),
			id: z.number(),
			name: z.string(),
			email: z.string().email(),
			createdAt: z.coerce.date(),
			stock: z.object({ title: z.string(), id: z.string() }).nullable(),
			department: z.object({ title: z.string(), id: z.string() }).nullable(),
		})
		.nullable(),
	id: ClientIdSchema,
	code: z.string().optional().nullable(),
	email: z.string().optional().nullable(),
	segment: SegmentSchema.nullable().nullable(),
	phones: z.array(PhoneSchema).optional(),
	contracts: z.array(ContractSchema.omit({ expiredAt: true })),
	activities: z.array(ServerSideOptionSchema),
	createdAt: z.coerce.date(),
	daysOfDelay: z.number(),
	lastDeal: z.string().nullable(),
	ordersCount: z.number(),
	status: ClientStatusSchema.optional().nullable(),
	firstRealization: z.string().optional().nullable(),
	totalSum: z.number().optional().nullable(),
	available: ClientPaymentStatisticSchema.optional().nullable(),
	waitingForPayment: ClientPaymentDetailSchema.optional().nullable(),
	prepaidPayments: ClientPaymentDetailSchema.optional().nullable(),
	overduePayments: ClientPaymentDetailSchema.optional().nullable(),
	isOfflineCreated: z.boolean().optional().nullable().default(false),
});

const IndividualClientSchema = BaseIndividualClientSchema.merge(BaseDetailedClientSchema.omit({ segment: true })).merge(ClientNameSchema);
const LegalClientSchema = BaseLegalClientSchema.merge(BaseDetailedClientSchema.omit({ segment: true })).merge(ClientNameSchema);
const ClientSchema = withStandardizedName(IndividualClientSchema.merge(ClientNameSchema).or(LegalClientSchema.merge(ClientNameSchema)));

export type ClientPreview = z.infer<typeof ClientPreviewSchema>;
export type Client = z.infer<typeof ClientSchema>;
export type LegalClient = z.infer<typeof LegalClientSchema>;
export type CreateClient = z.infer<typeof CreateClientSchema>;
export type ClientOption = z.infer<typeof ClientOptionSchema>;
export type ClientRawOption = { name: string; id: string };
