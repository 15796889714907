import clsx from 'clsx';
import { SearchResultListCompositionStrategy, SearchResultListProps, SearchResultListRenderPropsStrategy } from 'components/MainSearch/lib/types';
import SpinnerV2 from 'components/Spinner-v2';
import type { Option } from 'models/common/options';
import React, { Children } from 'react';
import { isObject } from 'utils/type-guards';

import styles from './styles.module.css';

const isRenderPropsStrategy = <T,>(props: unknown): props is SearchResultListRenderPropsStrategy<T> => {
	return isObject<T>(props) && 'render' in props;
};

export const SearchResultList = <T,>(props: SearchResultListProps<T>) => {
	let listElements: React.ReactNode[] = [];
	let needRenderEmptyState = false;
	let needRenderOptions = false;

	const { isLoading } = props;

	if (isRenderPropsStrategy(props)) {
		const { items, render, emptyState } = props;
		listElements = items.map((item, index) => {
			const onClick = () => props.onSelect(item as Option);

			return render({
				item,
				index,
				onClick,
			});
		});
		needRenderEmptyState = !isLoading && !!emptyState && listElements.length < 1;
		needRenderOptions = !isLoading && listElements.length > 0;
	} else {
		const { children, emptyState } = props as SearchResultListCompositionStrategy;
		listElements = Children.toArray(children);
		needRenderEmptyState = !isLoading && !!emptyState && listElements.length < 1;
		needRenderOptions = !isLoading && listElements.length > 0;
	}

	return (
		<div className={styles.searchBox}>
			<ul className={styles.scrollArea}>
				{needRenderEmptyState && (
					<li className={styles.emptyState}>
						<strong>{props.emptyState}</strong>
					</li>
				)}

				{needRenderOptions && listElements}

				{isLoading && (
					<li className={clsx(styles.loadingSpinner)}>
						<SpinnerV2 />
					</li>
				)}
			</ul>
		</div>
	);
};
