import { ROUTES_URLS } from 'const';
import { orderCanHooks } from 'pages/Order/OrderAbility/can/hooks';
import React from 'react';
import { Navigate } from 'react-router-dom';

const rootOrderIndex = 0;
const { useCanOrderSplitOrderManually } = orderCanHooks;

type CanSplitOrderRouteGuardProps = React.PropsWithChildren & {
	fallback?: React.ReactNode;
};

const CanSplitOrderRouteGuard: React.FC<CanSplitOrderRouteGuardProps> = ({ children, fallback }) => {
	const canAccess = useCanOrderSplitOrderManually(rootOrderIndex);

	if (canAccess) return children;
	if (fallback) return fallback;

	return <Navigate replace to={ROUTES_URLS.HOME} />;
};

export default CanSplitOrderRouteGuard;
