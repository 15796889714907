/* eslint-disable @typescript-eslint/no-unused-vars */
import { APP_MODE_STORAGE_KEY } from 'const';
import localforage from 'localforage';
import { createTransform } from 'redux-persist';
import type { RootState } from 'store';

function createPersistorStorage(dbName: string) {
	const db = localforage.createInstance({
		name: dbName,
	});

	return {
		db,
		getItem: db.getItem,
		setItem: db.setItem,
		removeItem: db.removeItem,
	};
}

const transform = createTransform(
	// Transform inbound state before persisting
	(inboundState: RootState['app']) => {
		const { calculatorButton: _, ...rest } = inboundState || {};

		return { ...rest } as RootState['app'];
	},
	// Transform outbound state when rehydrating
	(outboundState: RootState['app']) => {
		return {
			...(outboundState || {}),
			calculatorButton: 'closed',
		} as RootState['app'];
	},
	{ whitelist: ['app'] },
);

export const persistorStorage = createPersistorStorage(APP_MODE_STORAGE_KEY);
export const persistConfig = {
	key: 'root',
	storage: persistorStorage,
	whitelist: ['app'],
	transforms: [transform],
	serialize: false,
	deserialize: false,
};
