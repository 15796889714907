import { useCallback, useMemo, useState } from 'react';
import { isFunction } from 'utils/type-guards';

export const useStackState = <T>(initialList: T[] | (() => T[])) => {
	const [list, setList] = useState<T[]>(isFunction(initialList) ? initialList : [...initialList]);
	const length = list.length;

	const listInReverse = useMemo<T[]>(() => {
		const reverseList = [...list];
		reverseList.reverse();

		return reverseList;
	}, [list]);

	const push = useCallback(
		(item: T) => {
			const newList = [...list, item];
			setList(newList);

			return newList.length;
		},
		[list],
	);
	const pushBulk = useCallback(
		(items: T[], config?: { clearBefore?: boolean }) => {
			const newList = config?.clearBefore ? [...items] : [...list, ...items];
			setList(newList);

			return newList.length;
		},
		[list],
	);

	const pop = useCallback(
		(index?: number) => {
			if (list.length > 0) {
				const lastItem = list[list.length - 1];
				setList([...list.slice(0, index ?? list.length - 1)]);

				return lastItem;
			}

			return undefined;
		},
		[list],
	);

	const peek = useCallback(() => {
		if (list.length > 0) {
			return list[list.length - 1];
		}

		return undefined;
	}, [list]);

	const clear = useCallback(() => setList([]), []);
	const initial = useCallback((value?: T | T[]) => setList(Array.isArray(value) ? value : [value]), []);
	const replaceLast = useCallback((value: T) => {
		setList((prev) => {
			const temp = [...prev];
			temp.pop();
			temp.push(value);

			return temp;
		});
	}, []);

	const isEmpty = useCallback(() => list.length === 0, [list]);

	const controls = {
		clear,
		isEmpty,
		length,
		peek,
		pop,
		push,
		pushBulk,
		initial,
		replaceLast,
	};

	return [list, controls, listInReverse] as const;
};
