import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from 'const';
import localforage from 'localforage';
import { StoreToken } from 'models/auth';
import { type GeneratedPrintingTemplate, type PrintingTemplate, PrintingTemplateSchema } from 'models/printing-template';
import apiClient from 'services/apiClient';
import { printingTemplatesQueryKeys } from 'services/queryKeys';
import { baseQuery } from 'store/config/base-query';

import type { GenerateDocumentByTemplatePayload, PrintingTemplatesResponse, SendDocumentPayload } from './types';

export const printingTemplatesSliceApi = createApi({
	reducerPath: 'printingTemplates',
	baseQuery: baseQuery(),
	tagTypes: [printingTemplatesQueryKeys.printingTemplates()],
	endpoints: (builder) => ({
		getPrintingTemplates: builder.query<PrintingTemplate[], void>({
			query: () => ({ url: API_ENDPOINT.allPrintingTemplates(), method: 'GET' }),
			providesTags: (result) =>
				result
					? [
							printingTemplatesQueryKeys.printingTemplates(),
							...result.map((template) => printingTemplatesQueryKeys.printingTemplate(template.id)),
					  ]
					: [],
			transformResponse: (res: PrintingTemplatesResponse) => {
				const validation = PrintingTemplateSchema.array().safeParse(res.data);

				return validation.data;
			},
		}),
		generateDocumentByTemplate: builder.query<GeneratedPrintingTemplate, GenerateDocumentByTemplatePayload>({
			queryFn: async (dto) => {
				const authTokens = await localforage.getItem<StoreToken>('auth-tokens');
				const { accessToken, tokenType } = authTokens || {};
				const response = await apiClient.get(API_ENDPOINT.printingTemplates(dto.orderId, dto.type), {
					responseType: 'arraybuffer',
					headers: {
						Authorization: `${tokenType} ${accessToken}`,
					},
				});

				return {
					data: response.data,
				};
			},
		}),
		sendDocument: builder.mutation<void, SendDocumentPayload>({
			query: (dto) => ({ url: API_ENDPOINT.sendPrintingTemplate(), method: 'POST', data: dto }),
		}),
	}),
});

export const { useGetPrintingTemplatesQuery, useLazyGenerateDocumentByTemplateQuery, useSendDocumentMutation } = printingTemplatesSliceApi;
