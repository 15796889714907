import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';

import styles from './styles.module.css';
import type { AlertDialogueHeaderProps } from './types';

const AlertDialogueHeader = (
	{ children, className, xCloseButtonClassName, onClose }: AlertDialogueHeaderProps,
	ref: React.ForwardedRef<HTMLElement>,
) => {
	return (
		<header ref={ref} className={clsx(styles.header, className)}>
			<div>{children}</div>

			<button type="button" className={clsx(styles.xClose, xCloseButtonClassName)} onClick={onClose}>
				<XCloseIcon />
			</button>
		</header>
	);
};

export default forwardRef(AlertDialogueHeader);
