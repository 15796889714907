import { capitalize } from 'utils/shared';

import type { PermissionToken } from '../permissions';
import { useOrderAbility } from '../provider';

type ForgedHook<T extends string, P extends string> = { [K in T as `useCan${Capitalize<P>}${Capitalize<K>}`]: (suborderIndex: number) => boolean };

export const createOrderAbilityHookFactory = <T extends Record<string, PermissionToken>, P extends string>(
	permissions: T,
	prefix: P,
): ForgedHook<keyof T & string, P> => {
	const hooks = {} as ForgedHook<keyof T & string, P>;

	Object.entries(permissions ?? {}).forEach(([key, permissionToken]) => {
		hooks[`useCan${capitalize(prefix)}${capitalize(key)}`] = (suborderIndex: number) => {
			const ability = useOrderAbility();

			const { checkerTokens } = permissionToken;
			const { I: action, an: subject } = checkerTokens(suborderIndex);

			return ability.can(action, subject);
		};
	});

	return hooks;
};
